import { useIntl, defineMessages } from "react-intl";
import { Card, CheckboxGroup, RequiredValidator } from "@lysaab/ui-2";
import { useSpainContext, Bank } from "../../SpainContext";

const messages = defineMessages({
  bankHeader: { id: "spain.kyc.bank.header" },
  bankRequired: { id: "spain.kyc.bank.required" },
  [Bank.ES_SAN]: { id: "spain.kyc.bank.alt.ES_SAN" },
  [Bank.ES_BBVA]: { id: "spain.kyc.bank.alt.ES_BBVA" },
  [Bank.ES_CAIXA]: { id: "spain.kyc.bank.alt.ES_CAIXA" },
  [Bank.ES_SABADELL]: { id: "spain.kyc.bank.alt.ES_SABADELL" },
  [Bank.ES_BANKIA]: { id: "spain.kyc.bank.alt.ES_BANKIA" },
  [Bank.ES_OTHER]: { id: "spain.kyc.bank.alt.ES_OTHER" },
});

export const BankQuestion = () => {
  const intl = useIntl();
  const spainContext = useSpainContext();

  return (
    <Card>
      <CheckboxGroup
        alternatives={[
          {
            text: intl.formatMessage(messages[Bank.ES_SAN]),
            value: Bank.ES_SAN,
          },
          {
            text: intl.formatMessage(messages[Bank.ES_BBVA]),
            value: Bank.ES_BBVA,
          },
          {
            text: intl.formatMessage(messages[Bank.ES_CAIXA]),
            value: Bank.ES_CAIXA,
          },
          {
            text: intl.formatMessage(messages[Bank.ES_SABADELL]),
            value: Bank.ES_SABADELL,
          },
          {
            text: intl.formatMessage(messages[Bank.ES_BANKIA]),
            value: Bank.ES_BANKIA,
          },
          {
            text: intl.formatMessage(messages[Bank.ES_OTHER]),
            value: Bank.ES_OTHER,
          },
        ]}
        header={intl.formatMessage(messages.bankHeader)}
        validators={[
          new RequiredValidator(intl.formatMessage(messages.bankRequired)),
        ]}
        onChange={(banks) => spainContext.setState({ banks })}
        values={spainContext.state.banks || []}
      />
    </Card>
  );
};
