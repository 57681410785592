import React, { FunctionComponent, useRef, useContext, useEffect } from "react";
import { Button, Form, LysaFormRef } from "@lysaab/ui-2";
import { FormattedMessage } from "react-intl";
import { ImportantCard } from "./questions/ImportantCard";
import { ReactionCard } from "./questions/ReactionCard";
import { RiskPropensityCard } from "./questions/RiskPropensityCard";
import { useHistory } from "react-router-dom";
import { useCountryUrl } from "../../hooks/useCountryUrl";
import { SignupContext } from "../../state/SignupContext";
import {
  EventTracker,
  TrackerEvent,
} from "../../utils/eventTracker/EventTracker";
import { useAdvice } from "../../state/AdviceContext";
import { EligibilityRiskAnswer } from "../../data/types/Eligibility";

interface Props {
  next: () => void;
}

export const Risk: FunctionComponent<Props> = ({ next }) => {
  const formRef = useRef<LysaFormRef>();
  const history = useHistory();
  const countryUrl = useCountryUrl();
  const signupContext = useContext(SignupContext);
  const advice = useAdvice();

  useEffect(() => {
    if (!signupContext.state.email) {
      history.push(countryUrl);
    }
  }, [countryUrl, history, signupContext.state.email]);

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (formRef.current?.isValid) {
      const {
        [EligibilityRiskAnswer.IMPORTANCE]: riskImportance,
        [EligibilityRiskAnswer.PROPENSITY]: riskPropensity,
        [EligibilityRiskAnswer.REACTION]: riskReaction,
      } = advice.input;

      if (riskImportance && riskPropensity && riskReaction) {
        EventTracker.track({
          event: TrackerEvent.RISK,
          message: { riskImportance, riskPropensity, riskReaction },
        });
      }
      next();
    }
  };

  return (
    <Form lysaFormRef={formRef} onSubmit={handleSubmit}>
      <div>
        <h1>
          <FormattedMessage id="risk.header" />
        </h1>
        <section>
          <ImportantCard />
          <ReactionCard />
          <RiskPropensityCard />
        </section>
        <Button
          block
          type="submit"
          data-test-id="risk-next-button"
          label={<FormattedMessage id="risk.button.next" />}
        />
      </div>
    </Form>
  );
};
