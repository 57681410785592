import React, { useContext, useRef } from "react";
import { FormattedMessage } from "react-intl";
import { Card, Button, Form, LysaFormRef } from "@lysaab/ui-2";
import { saveInterested, removeInterested } from "../../../data/signup";
import { LocalizationContext } from "../../../state/LocalizationContext";
import { SignupContext } from "../../../state/SignupContext";
import { SpamQuestion } from "./SpamQuestion";
import { Plausible } from "../../../utils/Plausible";

interface Props {
  next: () => void;
}

export interface QuestionProps {
  onChange: () => void;
}

export const MarketingEmails: React.FC<Props> = ({ next }) => {
  const signupContext = useContext(SignupContext);
  const localizationContext = useContext(LocalizationContext);
  const formRef = useRef<LysaFormRef>();

  return (
    <div className="marketing-preferences-page">
      <h1>
        <FormattedMessage
          id="spain.marketing.header"
          description="Marketing emails page header"
        />
      </h1>

      <Form
        lysaFormRef={formRef}
        onSubmit={(event) => {
          event.preventDefault();
          if (formRef.current?.isValid) {
            if (
              !signupContext.state.email ||
              !localizationContext.state.country
            ) {
              return;
            }

            if (signupContext.state.spam?.value) {
              saveInterested(
                signupContext.state.email,
                localizationContext.state.language,
                localizationContext.state.country
              );
              Plausible.event({
                name: Plausible.events.ABOUT_YOU_SPAM_YES,
              });
            } else {
              removeInterested(
                signupContext.state.email,
                localizationContext.state.country
              );
              Plausible.event({
                name: Plausible.events.ABOUT_YOU_SPAM_NO,
              });
            }

            next();
          }
        }}
      >
        <Card>
          <SpamQuestion />
        </Card>

        <Button
          block
          type="submit"
          label={<FormattedMessage id="spain.marketing.next" />}
        />
      </Form>
    </div>
  );
};
