import React, { useContext, useRef } from "react";
import { FormattedMessage, defineMessages, useIntl } from "react-intl";
import {
  Card,
  Form,
  LysaFormRef,
  Snackbar,
  SNACKBAR_TYPES,
  Button,
} from "@lysaab/ui-2";
import { SignupContext } from "../../state/SignupContext";
import { FatcaQuestion } from "./Questions/FatcaQuestion";
import { TaxResidenceQuestion } from "./Questions/TaxResidenceQuestion";
import { SomeoneElsesMoneyQuestion } from "./Questions/SomeoneElsesMoneyQuestion";
import { LocalizationContext } from "../../state/LocalizationContext";
import "./AboutYou.scss";
import { TaxLink } from "../../components/taxLink/TaxLink";

const messages = defineMessages({
  fatcaError: { id: "about_you.fatcaError" },
  taxResidenceError: { id: "about_you.taxResidenceError" },
  someoneElsesMoneyError: { id: "about_you.someoneElsesMoneyError" },
});

interface Props {
  next: () => void;
}

export const AboutYou: React.FC<Props> = ({ next }) => {
  const signupContext = useContext(SignupContext);
  const localizationContext = useContext(LocalizationContext);
  const intl = useIntl();
  const formRef = useRef<LysaFormRef>();

  const { country } = localizationContext.state;

  return (
    <div className="about-you-page">
      <h1>
        <FormattedMessage id="about_you.header" />
      </h1>

      <Form
        lysaFormRef={formRef}
        onSubmit={(event) => {
          event.preventDefault();
          if (formRef.current?.isValid) {
            if (
              signupContext.state.fatca?.value === false &&
              signupContext.state.localTaxResidence?.value === true &&
              signupContext.state.someoneElsesMoney?.value === false
            ) {
              if (!signupContext.state.email || !country) {
                return;
              }

              next();
            }
          }
        }}
      >
        <Card>
          <FatcaQuestion />

          {signupContext.state.fatca?.value === true && (
            <Snackbar type={SNACKBAR_TYPES.ERROR}>
              {intl.formatMessage(messages.fatcaError)}
            </Snackbar>
          )}
        </Card>
        <Card>
          <TaxResidenceQuestion />

          {signupContext.state.localTaxResidence?.value === false && (
            <Snackbar type={SNACKBAR_TYPES.ERROR}>
              {intl.formatMessage<React.ReactNode>(messages.taxResidenceError, {
                country,
                help: (parts: React.ReactNode[]) => <TaxLink>{parts}</TaxLink>,
              })}
            </Snackbar>
          )}
        </Card>
        <Card>
          <SomeoneElsesMoneyQuestion />

          {signupContext.state.someoneElsesMoney?.value && (
            <Snackbar type={SNACKBAR_TYPES.ERROR}>
              {intl.formatMessage(messages.someoneElsesMoneyError)}
            </Snackbar>
          )}
        </Card>

        <Button
          block
          type="submit"
          data-test-id="aboutYou-next-button"
          label={<FormattedMessage id="about_you.next" />}
        />
      </Form>
    </div>
  );
};
