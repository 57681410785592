import { defineMessages, IntlShape } from "react-intl";
import { SustainabilityImportance } from "../../data/types/SustainabilityQuestions";

/** Question **/
const sustainabilityQuestionMessages = defineMessages({
  question: { id: "esg.sustainability.header" },
});

/** Title **/
const sustainabilityAltMessages = defineMessages<SustainabilityImportance>({
  [SustainabilityImportance.NOT_IMPORTANT]: {
    id: "esg.sustainability.alt.NOT_IMPORTANT.header",
  },
  [SustainabilityImportance.IMPORTANT]: {
    id: "esg.sustainability.alt.IMPORTANT.header",
  },
});

/** Description **/
const alternativeDescriptionMessages = defineMessages<SustainabilityImportance>(
  {
    [SustainabilityImportance.NOT_IMPORTANT]: {
      id: "esg.sustainability.alt.NOT_IMPORTANT.description",
    },
    [SustainabilityImportance.IMPORTANT]: {
      id: "esg.sustainability.alt.IMPORTANT.description",
    },
  }
);

/** Expanded description **/
const alternativeExpandDescriptionNotImportantMessage = defineMessages({
  item1: {
    id: "esg.sustainability.alt.NOT_IMPORTANT.expandDescription.item1",
  },
});

const alternativeExpandDescriptionImportantMessage = defineMessages({
  item1: {
    id: "esg.sustainability.alt.IMPORTANT.expandDescription.item1",
  },
  item2: {
    id: "esg.sustainability.alt.IMPORTANT.expandDescription.item2",
  },
});

const alternativeExpandDescriptionMessages = {
  [SustainabilityImportance.NOT_IMPORTANT]:
    alternativeExpandDescriptionNotImportantMessage,
  [SustainabilityImportance.IMPORTANT]:
    alternativeExpandDescriptionImportantMessage,
};

export function getSustainabilityQuestionText(intl: IntlShape) {
  return intl.formatMessage(sustainabilityQuestionMessages.question);
}

export function getSustainabilityAltText(
  intl: IntlShape,
  key: SustainabilityImportance
) {
  return intl.formatMessage(sustainabilityAltMessages[key]);
}

export function getSustainabilityDescriptionText(
  intl: IntlShape,
  key: SustainabilityImportance
) {
  return intl.formatMessage(alternativeDescriptionMessages[key]);
}

export function getSustainabilityExpandDescriptionText(
  intl: IntlShape,
  key: SustainabilityImportance
) {
  return Object.values(alternativeExpandDescriptionMessages[key]).map(
    (message) => intl.formatMessage(message)
  );
}
