import { defineMessages, IntlShape } from "react-intl";
import { NeedEarlierProbability } from "../../../data/types/HorizonQuestions";

const needEarlierProbabilityQuestionText = defineMessages({
  question: { id: "horizon.probability.header" },
});

const needEarlierProbabilityAltText = defineMessages<NeedEarlierProbability>({
  [NeedEarlierProbability.VERY_UNLIKELY]: {
    id: "horizon.probability.alt.veryunlikely",
  },
  [NeedEarlierProbability.UNLIKELY]: {
    id: "horizon.probability.alt.unlikely",
  },
  [NeedEarlierProbability.SOMEWHAT_UNLIKELY]: {
    id: "horizon.probability.alt.somewhatunlikely",
  },
  [NeedEarlierProbability.SOMEWHAT_LIKELY]: {
    id: "horizon.probability.alt.somewhatlikely",
  },
  [NeedEarlierProbability.LIKELY]: {
    id: "horizon.probability.alt.likely",
  },
  [NeedEarlierProbability.VERY_LIKELY]: {
    id: "horizon.probability.alt.verylikely",
  },
});

export function getNeedEarlierProbabilityQuestionText(intl: IntlShape) {
  return intl.formatMessage(needEarlierProbabilityQuestionText.question);
}

export function getNeedEarlierProbabilityAltText(
  intl: IntlShape,
  key: NeedEarlierProbability
) {
  return intl.formatMessage(needEarlierProbabilityAltText[key]);
}
