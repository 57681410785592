import { Alternative, Card, RadioGroup, RequiredValidator } from "@lysaab/ui-2";
import React, { FunctionComponent } from "react";
import { useIntl } from "react-intl";
import { PAIQuestionInfoModal } from "./modals/PAIQuestionInfoModal";
import { getPAIAltText, getPAIQuestionText } from "./PAIText";
import { PAIImportance } from "../../../data/types/SustainabilityQuestions";

interface Props {
  pai?: PAIImportance | Alternative<PAIImportance>;
  setPai: (pai: Alternative<PAIImportance>) => void;
}

export const PAIQuestion: FunctionComponent<Props> = ({ pai, setPai }) => {
  const intl = useIntl();
  const alternatives = Object.values(PAIImportance).map((key) => ({
    text: getPAIAltText(intl, key),
    value: key,
  }));

  const value =
    typeof pai === "string"
      ? {
          text: getPAIAltText(intl, pai),
          value: pai,
        }
      : pai;

  return (
    <Card>
      <RadioGroup
        data-test-id="sustainability-pai"
        alternatives={alternatives}
        header={
          <>
            {getPAIQuestionText(intl)}
            <br />
            <p>
              <PAIQuestionInfoModal />
            </p>
          </>
        }
        onChange={setPai}
        value={value}
        validators={[
          new RequiredValidator(intl.formatMessage({ id: "esg.pai.required" })),
        ]}
      />
    </Card>
  );
};
