import React, { FunctionComponent, useRef, useEffect, useContext } from "react";
import { Button, Form, LysaFormRef } from "@lysaab/ui-2";
import { FormattedMessage } from "react-intl";
import { Length } from "./questions/Length";
import { Probability } from "./questions/Probability";
import { useHistory } from "react-router-dom";
import { useCountryUrl } from "../../hooks/useCountryUrl";
import { SignupContext } from "../../state/SignupContext";

export const ROUTE = "/horizon";
interface Props {
  next: () => void;
}

export const Horizon: FunctionComponent<Props> = ({ next }) => {
  const formRef = useRef<LysaFormRef>();
  const history = useHistory();
  const countryUrl = useCountryUrl();
  const signupContext = useContext(SignupContext);

  useEffect(() => {
    if (!signupContext.state.email) {
      history.push(countryUrl);
    }
  }, [countryUrl, history, signupContext.state.email]);

  return (
    <Form
      lysaFormRef={formRef}
      onSubmit={(event) => {
        event.preventDefault();
        if (formRef.current?.isValid) {
          next();
        }
      }}
    >
      <div>
        <h1>
          <FormattedMessage id="horizon.header" />
        </h1>

        <section>
          <Length />
          <Probability />
        </section>

        <Button
          block
          type="submit"
          data-test-id="horizon-next-button"
          label={<FormattedMessage id="horizon.button.next" />}
        />
      </div>
    </Form>
  );
};
