import React, { FunctionComponent } from "react";
import { Alternative, Card, RadioGroup, RequiredValidator } from "@lysaab/ui-2";
import { useIntl } from "react-intl";
import { TaxonomyQuestionInfoModal } from "./modals/TaxonomyQuestionInfoModal";
import { getTaxonomyAltText, getTaxonomyQuestionText } from "./TaxonomyText";
import { TaxonomyImportance } from "../../../data/types/SustainabilityQuestions";

interface Props {
  taxonomy?: TaxonomyImportance | Alternative<TaxonomyImportance>;
  setTaxonomy: (SFDR: Alternative<TaxonomyImportance>) => void;
}

export const TaxonomyQuestion: FunctionComponent<Props> = ({
  taxonomy,
  setTaxonomy,
}) => {
  const intl = useIntl();
  const alternatives = Object.values(TaxonomyImportance).map((key) => ({
    text: getTaxonomyAltText(intl, key),
    value: key,
  }));

  const value =
    typeof taxonomy === "string"
      ? {
          text: getTaxonomyAltText(intl, taxonomy),
          value: taxonomy,
        }
      : taxonomy;

  return (
    <Card>
      <RadioGroup
        data-test-id="sustainability-share-taxonomy"
        alternatives={alternatives}
        header={
          <>
            {getTaxonomyQuestionText(intl)}
            <br />
            <p>
              <TaxonomyQuestionInfoModal />
            </p>
          </>
        }
        onChange={setTaxonomy}
        value={value}
        validators={[
          new RequiredValidator(
            intl.formatMessage({ id: "esg.taxonomy.required" })
          ),
        ]}
      />
    </Card>
  );
};
