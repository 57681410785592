import React, { FunctionComponent, useRef, useContext, useEffect } from "react";
import {
  Card,
  RadioGroup,
  Form,
  LysaFormRef,
  Snackbar,
  SNACKBAR_TYPES,
  Checkbox,
  RequiredValidator,
  Button,
} from "@lysaab/ui-2";
import { useIntl, defineMessages, FormattedMessage } from "react-intl";
import { SignupContext } from "../../state/SignupContext";
import { useHistory } from "react-router-dom";
import { useCountryUrl } from "../../hooks/useCountryUrl";
import "./Experience.scss";
import {
  EventTracker,
  TrackerEvent,
} from "../../utils/eventTracker/EventTracker";

const messages = defineMessages({
  hasInvestedHeader: {
    id: "experience.hasinvested.header",
  },
  hasInvestedTrue: {
    id: "experience.hasinvested.true",
  },
  hasInvestedFalse: {
    id: "experience.hasinvested.false",
  },
  hasInvestedRequired: {
    id: "experience.hasinvested.required",
  },
  hasInvestedWarningCheckbox: {
    id: "experience.hasinvested.checkbox",
  },
  hasInvestedWarningCheckboxRequired: {
    id: "experience.hasinvested.checkbox.required",
  },
  upAndDownHeader: {
    id: "experience.upanddown.header",
  },
  upAndDownTrue: {
    id: "experience.upanddown.true",
  },
  upAndDownFalse: {
    id: "experience.upanddown.false",
  },
  upAndDownRequired: {
    id: "experience.upanddown.required",
  },
  upAndDownWarningCheckbox: {
    id: "experience.upanddown.checkbox",
  },
  upAndDownWarningCheckboxRequired: {
    id: "experience.upanddown.checkbox.required",
  },
});

interface Props {
  next: () => void;
}

export const Experience: FunctionComponent<Props> = ({ next }) => {
  const intl = useIntl();
  const signupContext = useContext(SignupContext);
  const formRef = useRef<LysaFormRef>();
  const history = useHistory();
  const countryUrl = useCountryUrl();

  useEffect(() => {
    if (!signupContext.state.email) {
      history.push(countryUrl);
    }
  }, [countryUrl, history, signupContext.state.email]);

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (formRef.current?.isValid) {
      const { upAndDown, upAndDownCheckbox, hasInvested, hasInvestedCheckbox } =
        signupContext.state;
      EventTracker.track({
        event: TrackerEvent.EXPERIENCE,
        message: {
          upAndDown: upAndDown?.value,
          upAndDownCheckbox,
          hasInvested: hasInvested?.value,
          hasInvestedCheckbox,
        },
      });
      next();
    }
  };

  return (
    <div className="experience-page">
      <h1>
        <FormattedMessage id="experience.header" />
      </h1>

      <section>
        <Form lysaFormRef={formRef} onSubmit={handleSubmit}>
          <Card>
            <RadioGroup
              header={intl.formatMessage(messages.hasInvestedHeader)}
              alternatives={[
                {
                  text: intl.formatMessage(messages.hasInvestedTrue),
                  value: true,
                },
                {
                  text: intl.formatMessage(messages.hasInvestedFalse),
                  value: false,
                },
              ]}
              value={signupContext.state.hasInvested}
              onChange={(value) =>
                signupContext.setState({ hasInvested: value })
              }
              validators={[
                new RequiredValidator(
                  intl.formatMessage(messages.hasInvestedRequired)
                ),
              ]}
              data-test-id="has-invested-radiogroup"
            />
            {signupContext.state.hasInvested?.value === false && (
              <div data-test-id="has-invested-warning">
                <Snackbar icon={true} type={SNACKBAR_TYPES.WARNING}>
                  <FormattedMessage id="experience.hasinvested.warning" />
                </Snackbar>
                <Checkbox
                  alternative={{
                    text: intl.formatMessage(
                      messages.hasInvestedWarningCheckbox
                    ),
                    value: true,
                  }}
                  checked={!!signupContext.state.hasInvestedCheckbox}
                  onChange={() => {
                    signupContext.setState({
                      hasInvestedCheckbox:
                        !signupContext.state.hasInvestedCheckbox,
                    });
                  }}
                  validators={[
                    new RequiredValidator(
                      intl.formatMessage(
                        messages.hasInvestedWarningCheckboxRequired
                      )
                    ),
                  ]}
                />
              </div>
            )}
          </Card>
          <Card>
            <RadioGroup
              header={intl.formatMessage(messages.upAndDownHeader)}
              alternatives={[
                {
                  text: intl.formatMessage(messages.upAndDownTrue),
                  value: true,
                },
                {
                  text: intl.formatMessage(messages.upAndDownFalse),
                  value: false,
                },
              ]}
              value={signupContext.state.upAndDown}
              onChange={(value) => signupContext.setState({ upAndDown: value })}
              validators={[
                new RequiredValidator(
                  intl.formatMessage(messages.upAndDownRequired)
                ),
              ]}
              data-test-id="up-down-radiogroup"
            />
            {signupContext.state.upAndDown?.value === false && (
              <React.Fragment>
                <Snackbar icon={true} type={SNACKBAR_TYPES.WARNING}>
                  <FormattedMessage id="experience.upanddown.warning" />
                </Snackbar>
                <Checkbox
                  alternative={{
                    text: intl.formatMessage(messages.upAndDownWarningCheckbox),
                    value: true,
                  }}
                  checked={!!signupContext.state.upAndDownCheckbox}
                  onChange={() => {
                    signupContext.setState({
                      upAndDownCheckbox: !signupContext.state.upAndDownCheckbox,
                    });
                  }}
                  validators={[
                    new RequiredValidator(
                      intl.formatMessage(
                        messages.upAndDownWarningCheckboxRequired
                      )
                    ),
                  ]}
                />
              </React.Fragment>
            )}
          </Card>
          <Button
            block
            type="submit"
            data-test-id="experience-next-button"
            label={<FormattedMessage id="experience.button.next" />}
          />
        </Form>
      </section>
    </div>
  );
};
