import {
  NewIcon,
  RadioGroupCard,
  RadioGroupCardItem,
  RequiredValidator,
} from "@lysaab/ui-2";
import { useIntl } from "react-intl";
import { ExpandableContent } from "../../components/expandableContent/ExpandableContent";
import { useAdvice } from "../../state/AdviceContext";
import {
  getSustainabilityAltText,
  getSustainabilityDescriptionText,
  getSustainabilityExpandDescriptionText,
  getSustainabilityQuestionText,
} from "./SustainabilityText";
import { SustainabilityImportance } from "../../data/types/SustainabilityQuestions";

/** Icon **/
const alternativeIcon: Record<SustainabilityImportance, JSX.Element> = {
  [SustainabilityImportance.NOT_IMPORTANT]: (
    <NewIcon.Earth size={24} primaryColor={"#1840E3"} />
  ),
  [SustainabilityImportance.IMPORTANT]: <NewIcon.Sustainable size={24} />,
};

export const SustainabilityQuestion = () => {
  const intl = useIntl();
  const advice = useAdvice();

  const alternatives: RadioGroupCardItem<
    SustainabilityImportance | undefined
  >[] = Object.values(SustainabilityImportance).map((key) => ({
    header: getSustainabilityAltText(intl, key),
    description: getSustainabilityDescriptionText(intl, key),
    expandableContent: (
      <ExpandableContent>
        <ul>
          {Object.values(getSustainabilityExpandDescriptionText(intl, key)).map(
            (text, index) => (
              <li key={`selection-expandable-content-${index}`}>{text}</li>
            )
          )}
        </ul>
      </ExpandableContent>
    ),
    logo: <>{alternativeIcon[key]}</>,
    value: key,
  }));

  return (
    <RadioGroupCard
      data-test-id="sustainability-choice-radio-card"
      legend={<h1>{getSustainabilityQuestionText(intl)}</h1>}
      value={advice.input.sustainability}
      alternatives={alternatives}
      onChange={(sustainability) => advice.setInput({ sustainability })}
      validators={[
        new RequiredValidator(
          intl.formatMessage({ id: "esg.sustainability.required" })
        ),
      ]}
    />
  );
};
