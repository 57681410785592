import React, { useContext } from "react";
import { useIntl, defineMessages } from "react-intl";
import { Card, RadioGroup, RequiredValidator } from "@lysaab/ui-2";
import { useSpainContext, Deposits } from "../../SpainContext";
import { LocalizationContext } from "../../../state/LocalizationContext";

export const messages = defineMessages({
  depositsHeader: { id: "spain.kyc.deposits.header" },
  depositsRequired: { id: "spain.kyc.deposits.required" },
  [Deposits.ES_0_10K_EUR]: { id: "spain.kyc.deposits.alt.ES_0_10K_EUR" },
  [Deposits.ES_10K_100K_EUR]: {
    id: "spain.kyc.deposits.alt.ES_10K_100K_EUR",
  },
  [Deposits.ES_100K_500K_EUR]: {
    id: "spain.kyc.deposits.alt.ES_100K_500K_EUR",
  },
  [Deposits.ES_500K_EUR]: { id: "spain.kyc.deposits.alt.ES_500K_EUR" },
});

export const DepositsQuestion = () => {
  const intl = useIntl();
  const spainContext = useSpainContext();
  const localizationContext = useContext(LocalizationContext);

  return (
    <Card>
      <RadioGroup
        alternatives={[
          {
            text: intl.formatMessage(messages[Deposits.ES_0_10K_EUR], {
              currency: ([currencyStr]) =>
                intl.formatNumber(Number.parseInt(currencyStr, 10) || 0, {
                  currency: localizationContext.state.currency,
                  style: "currency",
                  minimumFractionDigits: 0,
                  maximumFractionDigits: 0,
                }),
            }),
            value: Deposits.ES_0_10K_EUR,
          },
          {
            text: intl.formatMessage(messages[Deposits.ES_10K_100K_EUR], {
              currency: ([currencyStr]) =>
                intl.formatNumber(Number.parseInt(currencyStr, 10) || 0, {
                  currency: localizationContext.state.currency,
                  style: "currency",
                  minimumFractionDigits: 0,
                  maximumFractionDigits: 0,
                }),
            }),
            value: Deposits.ES_10K_100K_EUR,
          },
          {
            text: intl.formatMessage(messages[Deposits.ES_100K_500K_EUR], {
              currency: ([currencyStr]) =>
                intl.formatNumber(Number.parseInt(currencyStr, 10) || 0, {
                  currency: localizationContext.state.currency,
                  style: "currency",
                  minimumFractionDigits: 0,
                  maximumFractionDigits: 0,
                }),
            }),
            value: Deposits.ES_100K_500K_EUR,
          },
          {
            text: intl.formatMessage(messages[Deposits.ES_500K_EUR], {
              currency: ([currencyStr]) =>
                intl.formatNumber(Number.parseInt(currencyStr, 10) || 0, {
                  currency: localizationContext.state.currency,
                  style: "currency",
                  minimumFractionDigits: 0,
                  maximumFractionDigits: 0,
                }),
            }),
            value: Deposits.ES_500K_EUR,
          },
        ]}
        header={intl.formatMessage(messages.depositsHeader)}
        validators={[
          new RequiredValidator(intl.formatMessage(messages.depositsRequired)),
        ]}
        onChange={(deposits) => spainContext.setState({ deposits })}
        value={spainContext.state.deposits}
        data-test-id="kyc-deposits"
      />
    </Card>
  );
};
