import React, { FunctionComponent, useReducer } from "react";
import { Alternative } from "@lysaab/ui-2";

export enum Bank {
  SE_SWEDBANK = "SE_SWEDBANK",
  SE_HB = "SE_HB",
  SE_NORDEA = "SE_NORDEA",
  SE_SEB = "SE_SEB",
  SE_LF = "SE_LF",
  SE_SKANDIA = "SE_SKANDIA",
  SE_DB = "SE_DB",
  SE_OTHER = "SE_OTHER",
}

export enum Deposits {
  SE_0_100K_SEK = "SE_0_100K_SEK",
  SE_100K_1M_SEK = "SE_100K_1M_SEK",
  SE_1M_5M_SEK = "SE_1M_5M_SEK",
  SE_5M_SEK = "SE_5M_SEK",
}

/**
 * This is the state for all generic sweden data.
 */
export interface SwedenState {
  tin?: string;
  banks?: Alternative<Bank>[];
  deposits?: Alternative<Deposits>;
}

// All types for the context. This includes setter methods
export interface SwedenContextProps {
  state: SwedenState;
  setState: (newState: Partial<SwedenState>) => void;
}

// Create the context. (This will just create a template)
export const SwedenContext = React.createContext<SwedenContextProps>(
  {} as SwedenContextProps
);

/**
 * A Higher Order Component. This is used to wrap classes so that the context is injected
 * There is quite a bit TS magic here. This is so that the internal props off the component
 * will be stripped of the context props so that the external props will only show the relevant
 * props
 */
export const withSweden =
  <P extends object>(
    Component: React.ComponentType<React.PropsWithChildren<P>>
  ): React.FC<React.PropsWithChildren<Omit<P, keyof SwedenContextProps>>> =>
  (props) =>
    (
      <SwedenContext.Consumer>
        {(contextProps) => <Component {...(props as P)} {...contextProps} />}
      </SwedenContext.Consumer>
    );

function stateReducer(state: SwedenState, newState: Partial<SwedenState>) {
  return { ...state, ...newState };
}

export const SwedenContextProvider: FunctionComponent<
  React.PropsWithChildren<unknown>
> = ({ children }) => {
  const [state, setState] = useReducer(stateReducer, {});

  return (
    <SwedenContext.Provider value={{ state, setState }}>
      {children}
    </SwedenContext.Provider>
  );
};
