import { LysaCountry } from "@lysaab/shared";
import { PepState, stateToRequest } from "@lysaab/lysa-pep";
import {
  Alternative,
  DanishTin,
  FinnishTin,
  GermanTin,
  SpanishTin,
  SwedishTin,
} from "@lysaab/ui-2";
import { defineMessages } from "react-intl";
import { SaveSignupOnfidoRequest } from "../../data/signup";
import { AdviceInput, AdviceResult } from "../../state/AdviceContext";
import { LocalizationState } from "../../state/LocalizationContext";
import { ReportingCountry, SignupState } from "../../state/SignupContext";
import { EventTracker } from "../../utils/eventTracker/EventTracker";
import {
  getAccountQuestions,
  isValidAccountQuestions,
} from "../../data/types/AccountQuestions";
import {
  getEligibilityFinancials,
  getEligibilityRiskAnswers,
  isValidEligibilityFinancials,
  isValidEligibilityRiskAnswers,
} from "../../data/types/Eligibility";

export const messages = defineMessages({
  accountName: { id: "confirmation.account.default.name" },
});

export enum AccountType {
  VP = "VP",
  ISK_SWE = "ISK_SWE",
  VP_SWE = "VP_SWE",
  DANICA_KF = "DANICA_KF",
  TJP_SWE = "TJP_SWE",
}

export function createSignupData(
  localizationState: LocalizationState,
  accountName: string,
  signupState: SignupState,
  pepState: PepState,
  adviceInput: AdviceInput,
  adviceResult: AdviceResult,
  banks?: Alternative<string>[],
  deposits?: Alternative<string>
): SaveSignupOnfidoRequest | null {
  const accountQuestions = getAccountQuestions(adviceInput);
  const eligibilityFinancials = getEligibilityFinancials(signupState.financial);
  const eligibilityRiskAnswers = getEligibilityRiskAnswers(adviceInput);

  if (
    typeof localizationState.country === "undefined" ||
    typeof signupState.email === "undefined" ||
    typeof signupState.crsReportingCountries === "undefined" ||
    typeof signupState.crsResidenceCountry === "undefined" ||
    typeof signupState.crsFirstName === "undefined" ||
    typeof signupState.crsLastName === "undefined" ||
    typeof signupState.crsStreet === "undefined" ||
    typeof signupState.crsPostCode === "undefined" ||
    typeof signupState.crsCity === "undefined" ||
    typeof signupState.crsBirthday === "undefined" ||
    signupState.crsBirthday === null ||
    typeof signupState.employment === "undefined" ||
    typeof signupState.moneyOrigin === "undefined" ||
    typeof signupState.purpose === "undefined" ||
    typeof signupState.depositInterval === "undefined" ||
    typeof signupState.withdrawalInterval === "undefined" ||
    typeof adviceResult.takenRisk === "undefined" ||
    typeof signupState.signedEmail === "undefined" ||
    typeof banks === "undefined" ||
    typeof deposits === "undefined" ||
    !isValidAccountQuestions(accountQuestions) ||
    !isValidEligibilityFinancials(eligibilityFinancials) ||
    !isValidEligibilityRiskAnswers(eligibilityRiskAnswers)
  ) {
    return null;
  }

  return {
    country: localizationState.country,
    language: localizationState.language,
    accountName,
    inviteId: signupState.inviteId,
    person: {
      email: signupState.email,
      citizenships: signupState.citizenships,
      crs: {
        reportingCountries: getCountrySpecificCrsData(
          signupState.crsReportingCountries.filter(
            ({ country }) => typeof country !== "undefined"
          )
        ),
        residentCountry: signupState.crsResidenceCountry,
        firstName: signupState.crsFirstName,
        lastName: signupState.crsLastName,
        street: signupState.crsStreet,
        postCode: signupState.crsPostCode,
        city: signupState.crsCity,
        birthday: signupState.crsBirthday,
      },
      sendInformationEmails: signupState.spam?.value,
    },
    advice: {
      ...accountQuestions,
      takenRisk: adviceResult.takenRisk,
      financial: eligibilityFinancials,
      riskAnswers: eligibilityRiskAnswers,
    },
    pep: stateToRequest(pepState),
    kyc: {
      customerKyc: {
        version: "3",
        questionAnswers: [
          {
            question: "EMPLOYMENT",
            answers: [signupState.employment],
          },
          {
            question: "MONEY_ORIGIN",
            answers: signupState.moneyOrigin.map((origin) => origin),
          },
          {
            question: "MONEY_BANK_ORIGIN",
            answers: banks.map((bank) => bank.value),
          },
          {
            question: "DEPOSIT_YEARLY_VALUE",
            answers: [deposits.value],
          },
        ],
      },
      accountKyc: {
        version: "3",
        questionAnswers: [
          {
            question: "PURPOSE",
            answers: signupState.purpose.map((purpose) => purpose),
          },
          {
            question: "DEPOSIT_INTERVAL",
            answers: [signupState.depositInterval],
          },
          {
            question: "WITHDRAWAL_INTERVAL",
            answers: [signupState.withdrawalInterval],
          },
        ],
      },
    },
    login: {
      password: signupState.password,
      signedEmail: signupState.signedEmail,
      signedSecret: !signupState.totpSignedSecret
        ? undefined
        : signupState.totpSignedSecret,
    },
    signupId: EventTracker.signupId,
  };
}

export function getCountrySpecificCrsData(
  crsReportingCountries: ReportingCountry[]
): ReportingCountry[] {
  return crsReportingCountries.map((data) => {
    const country = data.country;

    const tinNormalizerMap: Record<LysaCountry, (tin: string) => string> = {
      [LysaCountry.SWEDEN]: (tin: string) =>
        new SwedishTin(tin).getNormalizedTin(),
      [LysaCountry.DENMARK]: (tin: string) =>
        new DanishTin(tin).getNormalizedTin(),
      [LysaCountry.FINLAND]: (tin: string) =>
        new FinnishTin(tin).getNormalizedTin(),
      [LysaCountry.GERMANY]: (tin: string) =>
        new GermanTin(tin).getNormalizedTin(),
      [LysaCountry.SPAIN]: (tin: string) =>
        new SpanishTin(tin).getNormalizedTin(),
    };

    const tin =
      tinNormalizerMap[country as unknown as LysaCountry]?.(data.tin) ||
      data.tin;

    return { tin, country };
  });
}
