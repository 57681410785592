import { Alternative, Card, RadioGroup, RequiredValidator } from "@lysaab/ui-2";
import React, { FunctionComponent } from "react";
import { useIntl } from "react-intl";
import { SFDRQuestionInfoModal } from "./modals/SFDRQuestionInfoModal";
import { getSFDRAltText, getSFDRQuestionText } from "./SFDRText";
import { SFDRImportance } from "../../../data/types/SustainabilityQuestions";

interface Props {
  sfdr?: SFDRImportance | Alternative<SFDRImportance>;
  setSfdr: (sfdr: Alternative<SFDRImportance>) => void;
}

export const SFDRQuestion: FunctionComponent<Props> = ({ sfdr, setSfdr }) => {
  const intl = useIntl();
  const alternatives = Object.values(SFDRImportance).map((key) => ({
    text: getSFDRAltText(intl, key),
    value: key,
  }));

  const value =
    typeof sfdr === "string"
      ? {
          text: getSFDRAltText(intl, sfdr),
          value: sfdr,
        }
      : sfdr;

  return (
    <Card>
      <RadioGroup
        data-test-id="sustainability-share-sfdr"
        alternatives={alternatives}
        header={
          <>
            {getSFDRQuestionText(intl)}
            <br />
            <p>
              <SFDRQuestionInfoModal />
            </p>
          </>
        }
        onChange={setSfdr}
        value={value}
        validators={[
          new RequiredValidator(
            intl.formatMessage({ id: "esg.sfdr.required" })
          ),
        ]}
      />
    </Card>
  );
};
