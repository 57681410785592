import React, { FunctionComponent, useContext } from "react";
import { Slider, MinValidator } from "@lysaab/ui-2";
import {
  FormattedMessage,
  FormattedNumber,
  defineMessages,
  useIntl,
} from "react-intl";
import { LocalizationContext } from "../../../state/LocalizationContext";
import { SignupContext } from "../../../state/SignupContext";
import "./Slider.scss";

const messages = defineMessages({
  minValue: { id: "sliders.income.minvalue" },
});

interface Props {}

export const IncomeSlider: FunctionComponent<Props> = () => {
  const localizationContext = useContext(LocalizationContext);
  const signupContext = useContext(SignupContext);
  const intl = useIntl();

  const steps = localizationContext.state.situation?.income;

  if (!steps) {
    return null;
  }

  const max = steps.length - 1;
  const isMax =
    steps.indexOf(signupContext.state.financial.monthlyEarnings) === max;

  const label = (
    <div>
      <div className="situation-slider-text">
        <span>
          <FormattedMessage id="situation.slider.income.label" />
        </span>
        <span>
          {isMax && (
            <span style={{ fontWeight: "normal" }}>
              <FormattedMessage id="situation.slider.label.maxPrefix" />
            </span>
          )}
          <FormattedNumber
            // eslint-disable-next-line react/style-prop-object
            style="currency"
            value={signupContext.state.financial.monthlyEarnings}
            currency={localizationContext.state.currency}
            minimumFractionDigits={0}
            maximumFractionDigits={0}
          />
        </span>
      </div>
      <p>
        <FormattedMessage id="situation.slider.income.description" />
      </p>
    </div>
  );

  return (
    <div className="situation-slider">
      <Slider
        min={0}
        max={steps.length - 1}
        step={1}
        value={steps.indexOf(signupContext.state.financial.monthlyEarnings)}
        onChange={(value) =>
          signupContext.setState({
            financial: {
              ...signupContext.state.financial,
              monthlyEarnings: steps[value],
            },
          })
        }
        label={label}
        validators={[
          new MinValidator(1, intl.formatMessage(messages.minValue)),
        ]}
        data-test-id="income-slider"
      />
    </div>
  );
};
