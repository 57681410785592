import { Card, RadioGroup, RequiredValidator } from "@lysaab/ui-2";
import { useIntl, defineMessages } from "react-intl";
import { useAdvice } from "../../../state/AdviceContext";
import { EligibilityRiskAnswer } from "../../../data/types/Eligibility";

const messages = defineMessages({
  header: { id: "risk.reaction.header" },
  sellAlternative: { id: "risk.reaction.alt.sell" },
  keepAlternative: { id: "risk.reaction.alt.keep" },
  buyAlternative: { id: "risk.reaction.alt.buy" },
  required: { id: "risk.reaction.required" },
});

export enum RiskReaction {
  SELL = 3,
  KEEP = 7,
  BUY = 10,
}

export const ReactionCard = () => {
  const intl = useIntl();
  const advice = useAdvice();

  const alternatives = [
    {
      text: intl.formatMessage(messages.sellAlternative),
      value: RiskReaction.SELL,
    },
    {
      text: intl.formatMessage(messages.keepAlternative),
      value: RiskReaction.KEEP,
    },
    {
      text: intl.formatMessage(messages.buyAlternative),
      value: RiskReaction.BUY,
    },
  ];

  const value = alternatives.find(
    (alt) => alt.value === advice.input[EligibilityRiskAnswer.REACTION]
  );

  return (
    <Card>
      <RadioGroup
        alternatives={alternatives}
        header={intl.formatMessage(messages.header)}
        onChange={(riskReaction) =>
          advice.setInput({
            [EligibilityRiskAnswer.REACTION]: riskReaction.value,
          })
        }
        value={value}
        validators={[
          new RequiredValidator(intl.formatMessage(messages.required)),
        ]}
        data-test-id="risk-reaction-radiogroup"
      />
    </Card>
  );
};
