import { MaxValidator, MinValidator, MoneyInput } from "@lysaab/ui-2";
import { useContext } from "react";
import { defineMessages, useIntl } from "react-intl";
import { LocalizationContext } from "../../state/LocalizationContext";
import { DEFAULT_VALUES } from "./DefaultValues";

const messages = defineMessages({
  validatorMinInvestment: {
    id: "editallocation.validator.min-investment",
  },
  validatorMaxInvestment: {
    id: "editallocation.validator.max-investment",
  },
});

interface Props {
  allocationInvestment: number;
  setAllocationInvestment: (allocationInvestment: number) => void;
}

export const InvestmentInput: React.VFC<Props> = ({
  allocationInvestment,
  setAllocationInvestment,
}) => {
  const localizationContext = useContext(LocalizationContext);
  const intl = useIntl();

  return (
    <MoneyInput
      locale={localizationContext.getLocale()}
      currency={localizationContext.state.currency}
      value={allocationInvestment.toString()}
      onChange={(value) => setAllocationInvestment(parseInt(value, 10) || 0)}
      placeholder={intl.formatNumber(
        DEFAULT_VALUES[localizationContext.state.currency].defaultInvestment
      )}
      validators={[
        new MinValidator(
          DEFAULT_VALUES[localizationContext.state.currency].minInvestment,
          intl.formatMessage(messages.validatorMinInvestment, {
            min: intl.formatNumber(
              DEFAULT_VALUES[localizationContext.state.currency].minInvestment,
              {
                style: "currency",
                currency: localizationContext.state.currency,
                minimumFractionDigits: 0,
                maximumFractionDigits: 0,
              }
            ),
          })
        ),
        new MaxValidator(
          DEFAULT_VALUES[localizationContext.state.currency].maxInvestment,
          intl.formatMessage(messages.validatorMaxInvestment, {
            max: intl.formatNumber(
              DEFAULT_VALUES[localizationContext.state.currency].maxInvestment,
              {
                style: "currency",
                currency: localizationContext.state.currency,
                minimumFractionDigits: 0,
                maximumFractionDigits: 0,
              }
            ),
          })
        ),
      ]}
    />
  );
};
