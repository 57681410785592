import React, { useContext } from "react";
import { useIntl, defineMessages } from "react-intl";
import { Card, RadioGroup, RequiredValidator } from "@lysaab/ui-2";
import { DenmarkContext, Deposits } from "../../DenmarkContext";
import { LocalizationContext } from "../../../state/LocalizationContext";

export const messages = defineMessages({
  depositsHeader: { id: "denmark.kyc.deposits.header" },
  depositsRequired: { id: "denmark.kyc.deposits.required" },
  [Deposits.DK_0_100K_DKK]: { id: "denmark.kyc.deposits.alt.DK_0_100K_DKK" },
  [Deposits.DK_100K_1M_DKK]: { id: "denmark.kyc.deposits.alt.DK_100K_1M_DKK" },
  [Deposits.DK_1M_5M_DKK]: { id: "denmark.kyc.deposits.alt.DK_1M_5M_DKK" },
  [Deposits.DK_5M_DKK]: { id: "denmark.kyc.deposits.alt.DK_5M_DKK" },
});

export const DepositsQuestion = () => {
  const intl = useIntl();
  const denmarkContext = useContext(DenmarkContext);
  const localizationContext = useContext(LocalizationContext);

  return (
    <Card>
      <RadioGroup
        alternatives={[
          {
            text: intl.formatMessage(messages[Deposits.DK_0_100K_DKK], {
              currency: ([currencyStr]) =>
                intl.formatNumber(Number.parseInt(currencyStr, 10) || 0, {
                  currency: localizationContext.state.currency,
                  style: "currency",
                  minimumFractionDigits: 0,
                  maximumFractionDigits: 0,
                }),
            }),
            value: Deposits.DK_0_100K_DKK,
          },
          {
            text: intl.formatMessage(messages[Deposits.DK_100K_1M_DKK], {
              currency: ([currencyStr]) =>
                intl.formatNumber(Number.parseInt(currencyStr, 10) || 0, {
                  currency: localizationContext.state.currency,
                  style: "currency",
                  minimumFractionDigits: 0,
                  maximumFractionDigits: 0,
                }),
            }),
            value: Deposits.DK_100K_1M_DKK,
          },
          {
            text: intl.formatMessage(messages[Deposits.DK_1M_5M_DKK], {
              currency: ([currencyStr]) =>
                intl.formatNumber(Number.parseInt(currencyStr, 10) || 0, {
                  currency: localizationContext.state.currency,
                  style: "currency",
                  minimumFractionDigits: 0,
                  maximumFractionDigits: 0,
                }),
            }),
            value: Deposits.DK_1M_5M_DKK,
          },
          {
            text: intl.formatMessage(messages[Deposits.DK_5M_DKK], {
              currency: ([currencyStr]) =>
                intl.formatNumber(Number.parseInt(currencyStr, 10) || 0, {
                  currency: localizationContext.state.currency,
                  style: "currency",
                  minimumFractionDigits: 0,
                  maximumFractionDigits: 0,
                }),
            }),
            value: Deposits.DK_5M_DKK,
          },
        ]}
        header={intl.formatMessage(messages.depositsHeader)}
        validators={[
          new RequiredValidator(intl.formatMessage(messages.depositsRequired)),
        ]}
        onChange={(deposits) => denmarkContext.setState({ deposits })}
        value={denmarkContext.state.deposits}
        data-test-id="kyc-deposits"
      />
    </Card>
  );
};
