import React, {
  useContext,
  FunctionComponent,
  ReactNode,
  useEffect,
} from "react";
import { useHistory } from "react-router-dom";
import { SignupContext } from "../../state/SignupContext";

interface Props {
  children: ReactNode;
  aboutYouRoute: string;
}

export const AboutYouAnswersAccessGuard: FunctionComponent<Props> = ({
  children,
  aboutYouRoute,
}) => {
  const signupContext = useContext(SignupContext);
  const history = useHistory();

  useEffect(() => {
    if (
      signupContext.state.fatca?.value !== false ||
      signupContext.state.someoneElsesMoney?.value !== false ||
      signupContext.state.localTaxResidence?.value !== true
    ) {
      history.replace(aboutYouRoute);
    }
  }, [history, signupContext.state, aboutYouRoute]);

  return <React.Fragment>{children}</React.Fragment>;
};
