import { Card, RadioGroup, RequiredValidator } from "@lysaab/ui-2";
import { useIntl, defineMessages } from "react-intl";
import { useAdvice } from "../../../state/AdviceContext";
import { EligibilityRiskAnswer } from "../../../data/types/Eligibility";

const messages = defineMessages({
  header: { id: "risk.riskpropensity.header" },
  badAlternative: { id: "risk.riskpropensity.alt.bad" },
  prettyBadAlternative: { id: "risk.riskpropensity.alt.prettybad" },
  goodAlternative: { id: "risk.riskpropensity.alt.good" },
  prettyGoodAlternative: { id: "risk.riskpropensity.alt.prettygood" },
  tooRiskyAlternative: { id: "risk.riskpropensity.alt.toorisky" },
  required: { id: "risk.riskpropensity.required" },
});

export enum RiskPropensity {
  BAD = 10,
  PRETTY_BAD = 8,
  GOOD = 6,
  PRETTY_GOOD = 4,
  TOO_RISKY = 2,
}

export const RiskPropensityCard = () => {
  const intl = useIntl();
  const advice = useAdvice();

  const alternatives = [
    {
      text: intl.formatMessage(messages.badAlternative),
      value: RiskPropensity.BAD,
    },
    {
      text: intl.formatMessage(messages.prettyBadAlternative),
      value: RiskPropensity.PRETTY_BAD,
    },
    {
      text: intl.formatMessage(messages.goodAlternative),
      value: RiskPropensity.GOOD,
    },
    {
      text: intl.formatMessage(messages.prettyGoodAlternative),
      value: RiskPropensity.PRETTY_GOOD,
    },
    {
      text: intl.formatMessage(messages.tooRiskyAlternative),
      value: RiskPropensity.TOO_RISKY,
    },
  ];

  const value = alternatives.find(
    (alt) => alt.value === advice.input[EligibilityRiskAnswer.PROPENSITY]
  );

  return (
    <Card>
      <RadioGroup
        alternatives={alternatives}
        header={intl.formatMessage(messages.header)}
        onChange={(riskPropensity) =>
          advice.setInput({
            [EligibilityRiskAnswer.PROPENSITY]: riskPropensity.value,
          })
        }
        value={value}
        validators={[
          new RequiredValidator(intl.formatMessage(messages.required)),
        ]}
        data-test-id="risk-propensity-radiogroup"
      />
    </Card>
  );
};
