import { defineMessages, IntlShape } from "react-intl";
import { SavingsHorizonLength } from "../../../data/types/HorizonQuestions";

const savingsHorizonLengthQuestionText = defineMessages({
  question: { id: "horizon.length.header" },
});

const savingsHorizonLengthAltText = defineMessages<SavingsHorizonLength>({
  [SavingsHorizonLength.VERY_LONG]: {
    id: "horizon.length.alt.verylong",
  },
  [SavingsHorizonLength.LONG]: {
    id: "horizon.length.alt.long",
  },
  [SavingsHorizonLength.KINDA_LONG]: {
    id: "horizon.length.alt.kindalong",
  },
  [SavingsHorizonLength.MIDDLE]: {
    id: "horizon.length.alt.middle",
  },
  [SavingsHorizonLength.SHORT]: {
    id: "horizon.length.alt.short",
  },
});

export function getSavingsHorizonLengthQuestionText(intl: IntlShape) {
  return intl.formatMessage(savingsHorizonLengthQuestionText.question);
}

export function getSavingsHorizonLengthAltText(
  intl: IntlShape,
  key: SavingsHorizonLength
) {
  return intl.formatMessage(savingsHorizonLengthAltText[key]);
}
