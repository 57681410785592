import { PepState, stateToRequest } from "@lysaab/lysa-pep";
import { Alternative } from "@lysaab/ui-2";
import { SaveSignupNetsRequestFI } from "../../../data/signup";
import { getCountrySpecificCrsData } from "../../../pages/confirmation/ConfirmationHelpers";
import { AdviceInput, AdviceResult } from "../../../state/AdviceContext";
import { LocalizationState } from "../../../state/LocalizationContext";
import { SignupState } from "../../../state/SignupContext";
import { EventTracker } from "../../../utils/eventTracker/EventTracker";
import {
  getAccountQuestions,
  isValidAccountQuestions,
} from "../../../data/types/AccountQuestions";
import {
  getEligibilityFinancials,
  getEligibilityRiskAnswers,
  isValidEligibilityFinancials,
  isValidEligibilityRiskAnswers,
} from "../../../data/types/Eligibility";

export function createSignupDataNets(
  localizationState: LocalizationState,
  accountName: string,
  signupState: SignupState,
  pepState: PepState,
  adviceInput: AdviceInput,
  adviceResult: AdviceResult,
  banks?: Alternative<string>[],
  deposits?: Alternative<string>
): SaveSignupNetsRequestFI | null {
  const accountQuestions = getAccountQuestions(adviceInput);
  const eligibilityFinancials = getEligibilityFinancials(signupState.financial);
  const eligibilityRiskAnswers = getEligibilityRiskAnswers(adviceInput);

  if (
    typeof localizationState.country === "undefined" ||
    typeof signupState.email === "undefined" ||
    typeof signupState.crsReportingCountries === "undefined" ||
    typeof signupState.crsResidenceCountry === "undefined" ||
    typeof signupState.crsStreet === "undefined" ||
    typeof signupState.crsPostCode === "undefined" ||
    typeof signupState.crsCity === "undefined" ||
    typeof signupState.employment === "undefined" ||
    typeof signupState.moneyOrigin === "undefined" ||
    typeof signupState.purpose === "undefined" ||
    typeof signupState.depositInterval === "undefined" ||
    typeof signupState.withdrawalInterval === "undefined" ||
    typeof adviceResult.takenRisk === "undefined" ||
    typeof signupState.signedEmail === "undefined" ||
    typeof banks === "undefined" ||
    typeof deposits === "undefined" ||
    !isValidAccountQuestions(accountQuestions) ||
    !isValidEligibilityFinancials(eligibilityFinancials) ||
    !isValidEligibilityRiskAnswers(eligibilityRiskAnswers)
  ) {
    return null;
  }

  return {
    signupId: EventTracker.signupId,
    signedEmail: signupState.signedEmail,
    country: localizationState.country,
    language: localizationState.language,
    accountName,
    inviteId: signupState.inviteId,
    person: {
      email: signupState.email,
      citizenships: signupState.citizenships,
      crs: {
        reportingCountries: getCountrySpecificCrsData(
          signupState.crsReportingCountries.filter(
            ({ country, tin }) => country !== undefined && tin
          )
        ),
        residentCountry: signupState.crsResidenceCountry,
        street: signupState.crsStreet,
        postCode: signupState.crsPostCode,
        city: signupState.crsCity,
      },
      sendInformationEmails: signupState.spam?.value,
    },
    advice: {
      ...accountQuestions,
      takenRisk: adviceResult.takenRisk,
      financial: eligibilityFinancials,
      riskAnswers: eligibilityRiskAnswers,
    },
    pep: stateToRequest(pepState),
    kyc: {
      customerKyc: {
        version: "3",
        questionAnswers: [
          {
            question: "EMPLOYMENT",
            answers: [signupState.employment],
          },
          {
            question: "MONEY_ORIGIN",
            answers: signupState.moneyOrigin.map((origin) => origin),
          },
          {
            question: "MONEY_BANK_ORIGIN",
            answers: banks.map((bank) => bank.value),
          },
          {
            question: "DEPOSIT_YEARLY_VALUE",
            answers: [deposits.value],
          },
        ],
      },
      accountKyc: {
        version: "3",
        questionAnswers: [
          {
            question: "PURPOSE",
            answers: signupState.purpose.map((purpose) => purpose),
          },
          {
            question: "DEPOSIT_INTERVAL",
            answers: [signupState.depositInterval],
          },
          {
            question: "WITHDRAWAL_INTERVAL",
            answers: [signupState.withdrawalInterval],
          },
        ],
      },
    },
  };
}
