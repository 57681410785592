import { MoneyInput } from "@lysaab/ui-2";
import { useContext } from "react";
import { useIntl } from "react-intl";
import { LocalizationContext } from "../../state/LocalizationContext";
import { DEFAULT_VALUES } from "./DefaultValues";

interface Props {
  monthly: number;
  setMonthly: (value: number) => void;
}

export const MonthlyInvestmentInput: React.VFC<Props> = ({
  monthly,
  setMonthly,
}) => {
  const localizationContext = useContext(LocalizationContext);
  const intl = useIntl();

  return (
    <MoneyInput
      locale={localizationContext.getLocale()}
      currency={localizationContext.state.currency}
      value={monthly.toString()}
      onChange={(value) => setMonthly(parseFloat(value) || 0)}
      placeholder={intl.formatNumber(
        DEFAULT_VALUES[localizationContext.state.currency].monthly
      )}
    />
  );
};
