import React, { FunctionComponent, useContext, useState } from "react";
import { SignupContext, SignupState } from "../../../state/SignupContext";
import { SwedenContext, SwedenState } from "../../SwedenContext";
import { LysaCountry } from "@lysaab/shared";
import { PepContext, stateToRequest, PepState } from "@lysaab/lysa-pep";
import {
  LocalizationContext,
  LocalizationState,
} from "../../../state/LocalizationContext";
import { useIntl, IntlShape } from "react-intl";
import { ServerError, Spinner } from "@lysaab/ui-2";
import {
  SaveSignupCrsRequestSE,
  SaveSignupRequestSE,
  SignupId,
  saveSignupSE,
} from "../../../data/signup";
import { ConfirmationTextSection } from "../../../pages/confirmation/ConfirmationTextSection";
import { ConfirmationSummarySection } from "../../../pages/confirmation/ConfirmationSummarySection";
import {
  AccountType,
  getCountrySpecificCrsData,
  messages,
} from "../../../pages/confirmation/ConfirmationHelpers";
import { ConfirmationErrorSnackbar } from "../../../pages/confirmation/ConfirmationErrorSnackbar";
import { ConfirmationHeader } from "../../../pages/confirmation/ConfirmationHeader";
import { ConfirmationNextButton } from "../../../pages/confirmation/ConfirmationNextButton";
import {
  EventTracker,
  TrackerEvent,
} from "../../../utils/eventTracker/EventTracker";
import { useDocumentsInformation } from "../../../hooks/useDocumentsInformation";
import {
  AdviceInput,
  AdviceResult,
  useAdvice,
} from "../../../state/AdviceContext";
import {
  AccountQuestions,
  getAccountQuestions,
  isValidAccountQuestions,
} from "../../../data/types/AccountQuestions";
import {
  getEligibilityFinancials,
  getEligibilityRiskAnswers,
  isValidEligibilityFinancials,
  isValidEligibilityRiskAnswers,
} from "../../../data/types/Eligibility";

interface Props {
  next: (signupId: SignupId) => void;
}

export const Confirmation: FunctionComponent<Props> = ({ next }) => {
  const signupContext = useContext(SignupContext);
  const swedenContext = useContext(SwedenContext);
  const pepContext = useContext(PepContext);
  const localizationContext = useContext(LocalizationContext);
  const advice = useAdvice();
  const intl = useIntl();
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const {
    information,
    error: documentsError,
    isLoading: isLoadingInformation,
  } = useDocumentsInformation();

  const signup = () => {
    EventTracker.track({ event: TrackerEvent.CONFIRM });
    const signupRequest = createSignupData(
      localizationContext.state,
      intl,
      signupContext.state,
      swedenContext.state,
      pepContext.state,
      advice.input,
      advice.result
    );

    if (signupRequest === null) {
      console.error(
        "signupRequest === null",
        localizationContext.state,
        signupContext.state,
        swedenContext.state,
        pepContext.state
      );

      setError(true);

      return;
    }

    setLoading(true);
    saveSignupSE(signupRequest)
      .then((response) => {
        EventTracker.track({ event: TrackerEvent.SUBMITTED });
        next(response.signupId);
      })
      .catch((error: ServerError<unknown>) => {
        setLoading(false);
        console.error(error, "signupRequest", signupRequest);
        setError(true);
      });
  };

  return (
    <div className="confirmation-page">
      <ConfirmationHeader />

      {loading || isLoadingInformation ? (
        <Spinner />
      ) : (
        <>
          {(error || documentsError) && <ConfirmationErrorSnackbar />}
          <ConfirmationTextSection documentsInformation={information} />
          <ConfirmationSummarySection
            accountType={AccountType.ISK_SWE}
            showInvestmentType={true}
          />
          <ConfirmationNextButton onClick={signup} />
        </>
      )}
    </div>
  );
};

function createSignupData(
  localizationState: LocalizationState,
  intl: IntlShape,
  signupState: SignupState,
  swedenState: SwedenState,
  pepState: PepState,
  adviceInput: AdviceInput,
  adviceResult: AdviceResult
): SaveSignupRequestSE | null {
  const accountQuestions = getAccountQuestions(adviceInput);
  const eligibilityFinancials = getEligibilityFinancials(signupState.financial);
  const eligibilityRiskAnswers = getEligibilityRiskAnswers(adviceInput);

  if (
    typeof localizationState.country === "undefined" ||
    typeof signupState.email === "undefined" ||
    typeof signupState.employment === "undefined" ||
    typeof signupState.moneyOrigin === "undefined" ||
    typeof signupState.someoneElsesMoney === "undefined" ||
    typeof swedenState.banks === "undefined" ||
    typeof signupState.purpose === "undefined" ||
    typeof signupState.depositInterval === "undefined" ||
    typeof signupState.withdrawalInterval === "undefined" ||
    typeof swedenState.deposits === "undefined" ||
    typeof adviceResult.takenRisk === "undefined" ||
    typeof signupState.signedEmail === "undefined" ||
    !isValidAccountQuestions(accountQuestions) ||
    !isValidEligibilityFinancials(eligibilityFinancials) ||
    !isValidEligibilityRiskAnswers(eligibilityRiskAnswers)
  ) {
    return null;
  }

  let data: Partial<AccountQuestions> = getAccountQuestions(adviceInput);

  if (!isValidAccountQuestions(data)) {
    throw new Error("Data is not valid account questions");
  }

  return {
    country: localizationState.country,
    language: localizationState.language,
    accountName: intl.formatMessage(messages.accountName),
    inviteId: signupState.inviteId,
    person: {
      email: signupState.email,
      crs: getCrsRequest(signupState),
      citizenships: signupState.citizenships,
    },
    advice: {
      ...data,
      takenRisk: adviceResult.takenRisk,
      financial: eligibilityFinancials,
      riskAnswers: eligibilityRiskAnswers,
    },
    pep: stateToRequest(pepState),
    kyc: {
      customerKyc: {
        version: "3",
        questionAnswers: [
          {
            question: "EMPLOYMENT",
            answers: [signupState.employment],
          },
          {
            question: "MONEY_ORIGIN",
            answers: signupState.moneyOrigin.map((origin) => origin),
          },
          {
            question: "MONEY_BANK_ORIGIN",
            answers: swedenState.banks.map((bank) => bank.value),
          },
          {
            question: "DEPOSIT_YEARLY_VALUE",
            answers: [swedenState.deposits.value],
          },
        ],
      },
      accountKyc: {
        version: "3",
        questionAnswers: [
          {
            question: "PURPOSE",
            answers: signupState.purpose.map((purpose) => purpose),
          },
          {
            question: "DEPOSIT_INTERVAL",
            answers: [signupState.depositInterval],
          },
          {
            question: "WITHDRAWAL_INTERVAL",
            answers: [signupState.withdrawalInterval],
          },
        ],
      },
    },
    signupId: EventTracker.signupId,
    signedEmail: signupState.signedEmail,
  };
}

function getCrsRequest(
  signupState: SignupState
): SaveSignupCrsRequestSE | undefined {
  if (
    signupState.crsReportingCountries !== undefined &&
    signupState.crsReportingCountries.length > 0
  ) {
    return {
      reportingCountries: getCountrySpecificCrsData(
        signupState.crsReportingCountries || []
      ),
      residentCountry: signupState.crsResidenceCountry || LysaCountry.SWEDEN,
      street: signupState.crsStreet,
      postCode: signupState.crsPostCode,
      city: signupState.crsCity,
    };
  }
  return undefined;
}
