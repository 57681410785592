import {
  ExpandableSneakPeakCard,
  Slider,
  STORY_ANIMATION_DURATION,
  useDebounceValue,
} from "@lysaab/ui-2";
import React, { useContext, useEffect, useRef, useState } from "react";
import { FormattedMessage, defineMessages, useIntl } from "react-intl";
import { DEFAULT_VALUES } from "./DefaultValues";
import { useHistory } from "react-router-dom";
import { LocalizationContext } from "../../state/LocalizationContext";
import { getFees, GetFeesResponse } from "../../data/fees";
import { Amount } from "../../components/amount/Amount";
import { FormattedPercentage } from "../../components/formattedPercentage/FormattedPercentage";
import { InvestmentInput } from "./InvestmentInput";
import { MonthlyInvestmentInput } from "./MonthlyInvestmentInput";

import "./EditAllocationFees.scss";
import { simulateFutureWorthAndFee } from "./feeHelpers";
import { useSignupContext } from "../../state/SignupContext";
import { useAdvice } from "../../state/AdviceContext";
import { LysaCountry } from "@lysaab/shared";
import { AccountType } from "../confirmation/ConfirmationHelpers";
import { DescriptionModal } from "../../components/descriptionModal/DescriptionModal";

const messages = defineMessages({
  showMoreButton: {
    id: "editallocation.fees.showmore",
  },
  showLessButton: {
    id: "editallocation.fees.showless",
  },
});

const DEBOUNCE_TIMER = 500;

const INITIAL_FEES = {
  future: {
    discretionary: 0,
    fundManagement: 0,
    fundAssets: 0,
    transactionFees: 0,
    total: 0,
  },
  cost: {
    discretionary: 0,
    fundManagement: 0,
    fundAssets: 0,
    transactionFees: 0,
    total: 0,
  },
};

interface Props {
  monthlyInvestment: number;
  setMonthlyInvestment: (value: number) => void;
  horizonYears: number;
  setHorizonYears: (allocationInvestment: number) => void;
}

export const EditAllocationFees: React.VFC<Props> = ({
  monthlyInvestment,
  setMonthlyInvestment,
  horizonYears,
  setHorizonYears,
}) => {
  const intl = useIntl();
  const history = useHistory();
  const localizationContext = useContext(LocalizationContext);
  const containerDiv = useRef<HTMLDivElement>(null);
  const [estimatedFees, setEstimatedFees] =
    useState<GetFeesResponse>(INITIAL_FEES);
  const [expectedYield, setExpectedYield] = useState(7);
  const signupContext = useSignupContext();
  const advice = useAdvice();

  const investmentType = advice.result.investmentType;
  const risk = signupContext.state.allocationSelectedRisk;
  const allocationInvestment = signupContext.state.allocationInvestment;

  const debouncedRisk = useDebounceValue(risk, DEBOUNCE_TIMER);

  useEffect(() => {
    const search = new URLSearchParams(history.location.search);
    if (search.has("fees")) {
      setTimeout(
        () => containerDiv.current?.scrollIntoView({ behavior: "smooth" }),
        STORY_ANIMATION_DURATION
      );
    }
  }, [history.location.search]);

  useEffect(() => {
    if (!localizationContext.state.country || !investmentType) {
      return;
    }

    if (
      allocationInvestment <
        DEFAULT_VALUES[localizationContext.state.currency].minInvestment ||
      allocationInvestment >
        DEFAULT_VALUES[localizationContext.state.currency].maxInvestment
    ) {
      setEstimatedFees(INITIAL_FEES);
      return;
    }

    getFees({
      amount: parseInt(allocationInvestment.toString(), 10),
      risk: debouncedRisk,
      investmentType: investmentType,
      country: localizationContext.state.country,
      accountType:
        localizationContext.state.country === LysaCountry.SWEDEN
          ? AccountType.ISK_SWE
          : AccountType.VP,
    }).then((fees) => {
      setEstimatedFees(fees);
    });
  }, [
    debouncedRisk,
    investmentType,
    localizationContext.state.currency,
    localizationContext.state.country,
    allocationInvestment,
  ]);

  if (!localizationContext.state.country) {
    return null;
  }

  // We don't care about fees.future because we make our own calculations.
  // So we use fees.cost, which is in percentage, like 0.12%
  // fees.rebase is expressed in basis points, so we divide it by 100 to get it
  // in percentage instead
  const fees = estimatedFees || INITIAL_FEES;

  const fundManagementPercentage = fees.cost.fundManagement;
  const fundsPercentage = fees.cost.fundAssets;
  const transactionsPercentage = fees.cost.transactionFees;

  const simulated = simulateFutureWorthAndFee({
    initialInvestment: allocationInvestment,
    monthlyInvestment,
    years: horizonYears,
    expectedYearlyYield: expectedYield,
    yearlyFundFeePercentage: fundsPercentage,
    yearlyTransactionFeePercentage: transactionsPercentage,
    yearlyLysaFundManagementFeePercentage: fundManagementPercentage,
    country: localizationContext.state.country,
    customerAum: 0,
  });
  const lysaMinPercentage = fundManagementPercentage + simulated.minLysaFee;
  const lysaMaxPercentage = fundManagementPercentage + simulated.maxLysaFee;
  const minTotalPercentage =
    lysaMinPercentage + fundsPercentage + transactionsPercentage;
  const maxTotalPercentage =
    lysaMaxPercentage + fundsPercentage + transactionsPercentage;
  const fundsCost = simulated.totalFees.fundFee;
  const transactionsCost = simulated.totalFees.transactionFee;
  const lysaCost =
    simulated.totalFees.discretionaryFee +
    simulated.totalFees.lysaFundManagementFee;

  return (
    <div
      className="edit-allocation-fees-page card-with-top-margin"
      ref={containerDiv}
    >
      <ExpandableSneakPeakCard
        buttonOpenText={intl.formatMessage(messages.showMoreButton)}
        buttonCloseText={intl.formatMessage(messages.showLessButton)}
        startOpen={/\bfees\b/.test(history.location.search)}
      >
        <>
          <div className="header">
            <h3>
              <FormattedMessage id="editallocation.fees.header" />
            </h3>
          </div>
          <div className="controls">
            <h4>
              <FormattedMessage id="editallocation.text.example-initial-investment" />
            </h4>
            <InvestmentInput
              allocationInvestment={allocationInvestment}
              setAllocationInvestment={(allocationInvestment: number) => {
                signupContext.setState({ allocationInvestment });
              }}
            />
            <h4>
              <FormattedMessage id="editallocation.text.example-monthly-investment" />
            </h4>
            <MonthlyInvestmentInput
              monthly={monthlyInvestment}
              setMonthly={setMonthlyInvestment}
            />
            <Slider
              min={1}
              max={50}
              step={1}
              value={horizonYears}
              onChange={(value) => setHorizonYears(value)}
              label={
                <h4 className="horizon-slider-title">
                  <strong>
                    <FormattedMessage id="editallocation.text.horizon" />
                  </strong>
                  <i>
                    <FormattedMessage
                      id="editallocation.text.horizon.years"
                      values={{ horizon: horizonYears }}
                    />
                  </i>
                </h4>
              }
            />
            <Slider
              min={0}
              max={10}
              step={0.1}
              value={expectedYield}
              onChange={(value) => setExpectedYield(value)}
              label={
                <h4 className="expected-yield-slider-title">
                  <strong>
                    <FormattedMessage id="editallocation.text.expected" />
                  </strong>
                  <i>
                    <FormattedPercentage value={expectedYield} decimals={1} />
                  </i>
                </h4>
              }
            />
          </div>
          <div className="summary">
            <div className="header">
              <h4>
                <FormattedMessage
                  id="editallocation.fees.summary.header"
                  values={{ horizon: horizonYears }}
                />
              </h4>
            </div>
            <dl>
              <dt>
                <FormattedMessage id="editallocation.text.lysa" />
                {": "}
                {lysaMinPercentage === lysaMaxPercentage ? (
                  <FormattedPercentage value={lysaMinPercentage} decimals={3} />
                ) : (
                  <>
                    <FormattedPercentage
                      value={lysaMinPercentage}
                      decimals={3}
                    />{" "}
                    -{" "}
                    <FormattedPercentage
                      value={lysaMaxPercentage}
                      decimals={3}
                    />
                  </>
                )}
              </dt>
              <dd>
                <Amount amount={lysaCost} />
              </dd>
              <dt>
                <FormattedMessage id="editallocation.fees.funds" />
                {": "}
                <FormattedPercentage value={fundsPercentage} decimals={3} />
              </dt>
              <dd>
                <Amount amount={fundsCost} />
              </dd>
              <dt>
                <FormattedMessage id="editallocation.fees.transactions" />
                {": "}
                <FormattedPercentage
                  value={transactionsPercentage}
                  decimals={3}
                />
              </dt>
              <dd>
                <Amount amount={transactionsCost} />
              </dd>
              <dt className="total">
                <FormattedMessage id="editallocation.fees.total" />
                {": "}
                {minTotalPercentage === maxTotalPercentage ? (
                  <FormattedPercentage
                    value={minTotalPercentage}
                    decimals={3}
                  />
                ) : (
                  <>
                    <FormattedPercentage
                      value={minTotalPercentage}
                      decimals={3}
                    />
                    {" - "}
                    <FormattedPercentage
                      value={maxTotalPercentage}
                      decimals={3}
                    />
                  </>
                )}
              </dt>
              <dd className="total">
                <Amount amount={simulated.fee} />
              </dd>
            </dl>
          </div>
          <div className="fee-disclaimer">
            <p>
              <FormattedMessage id="editallocation.fees.disclaimer" />
            </p>
            <p>
              <LysaFeeDescription
                userCountry={localizationContext.state.country}
              />
            </p>
          </div>
        </>
      </ExpandableSneakPeakCard>
    </div>
  );
};

const LysaFeeDescription = ({ userCountry }: { userCountry?: LysaCountry }) => {
  const intl = useIntl();

  // TODO: Remove when the article is translated to all languages
  if (userCountry !== LysaCountry.SWEDEN) {
    return null;
  }

  return (
    <FormattedMessage
      id="editallocation.fees.description"
      values={{
        link: (parts: React.ReactNode[]) => (
          <DescriptionModal
            header={intl.formatMessage({
              id: "editallocation.fees.description.header",
            })}
            content={intl.formatMessage(
              {
                id: "editallocation.fees.description.content",
              },
              {
                bold: (
                  parts: (string | React.ReactNode)[]
                ): React.ReactNode => <b>{parts}</b>,
                break: (
                  parts: (string | React.ReactNode)[]
                ): React.ReactNode => (
                  <>
                    {parts}
                    <br />
                    <br />
                  </>
                ),
              }
            )}
          >
            {parts}
          </DescriptionModal>
        ),
      }}
    />
  );
};
