import { useContext, useEffect, useRef } from "react";
import { Spinner, Story, WorldCountry } from "@lysaab/ui-2";
import { LysaCountry } from "@lysaab/shared";
import {
  Switch,
  Route,
  matchPath,
  useHistory,
  useLocation,
} from "react-router-dom";
import {
  IsPepPage,
  RelationPage,
  PepContext,
  PepType,
  PepStatusPage,
  PepRolePage,
} from "@lysaab/lysa-pep";
import { Intro } from "./pages/intro/Intro";
import { Experience } from "../pages/experience/Experience";
import {
  LocalizationContext,
  Language,
  SituationSteps,
  Currency,
} from "../state/LocalizationContext";
import { Situation } from "../pages/situation/Situation";
import { Risk } from "../pages/risk/Risk";
import { Horizon, ROUTE as HORIZON_ROUTE } from "../pages/horizon/Horizon";
import { defineMessages, useIntl } from "react-intl";
import {
  Advice,
  getSuitabilityAssessmentModel,
  ROUTE as ADVICE_ROUTE,
} from "../pages/advice/Advice";
import {
  EditAllocation,
  ROUTE as EDIT_ALLOCATION_ROUTE,
} from "../pages/EditAllocation/EditAllocation";
import {
  RiskWarning,
  ROUTE as RISK_WARNING_ROUTE,
} from "../pages/RiskWarning/RiskWarning";
import { Kyc, ROUTE as KYC_ROUTE } from "./components/kyc/Kyc";
import {
  Citizenship,
  ROUTE as CITIZENSHIP_ROUTE,
} from "../pages/citizenship/citizenship";
import { generateTranslatedArray } from "../pages/situation/sliders/Interval";
import { Confirmation } from "./pages/confirmation/Confirmation";
import { useSafeNavigation } from "../hooks/useSafeNavigation";
import { Sign, useSignBankId } from "./pages/sign/Sign";
import { Done } from "./pages/done/Done";
import { ContactFooter } from "../components/ContactFooter";
import { NoDirectAccessRoute } from "../components/DirectAccessGuard";
import { AboutYou } from "../pages/aboutYou/AboutYou";
import { Exit } from "../pages/exit/Exit";
import { ROUTE as CRS_ROUTE } from "../pages/crs/Crs";
import { Crs } from "./pages/crs/Crs";
import { EventTracker, TrackerEvent } from "../utils/eventTracker/EventTracker";
import {
  VerifyEmail,
  ROUTE as EMAIL_ROUTE,
} from "../pages/verifyEmail/VerifyEmail";
import {
  Sustainability,
  ROUTE as SUSTAINABILITY_ROUTE,
} from "../pages/sustainability/Sustainability";

import {
  SustainabilityPreference,
  ROUTE as SUSTAINABILITY_PREFERENCE_ROUTE,
} from "../pages/sustainabilityPreference/SustainabilityPreference";
import {
  SustainabilityImportant,
  ROUTE as SUSTAINABILITY_QUESTIONS_ROUTE,
} from "../pages/sustainabilityImportant/SustainabilityImportant";
import { getSuitabilityAssessment } from "../data/Investments";
import {
  SignupContextProvider,
  useSignupContext,
} from "../state/SignupContext";
import {
  ConfirmEsgUpdate,
  ROUTE as CONFIRM_ESG_UPDATE_ROUTE,
} from "../pages/confirmEsgUpdate/ConfirmEsgUpdate";
import { SituationAnswersAccessGuard } from "../components/situationAnswersAccessGuard/SituationAnswersAccessGuard";
import { AboutYouAnswersAccessGuard } from "../components/aboutYouAnswersAccessGuard/AboutYouAnswersAccessGuard";
import { AdviceGuard } from "../components/adviceGuard/AdviceGuard";
import { useAdvice } from "../state/AdviceContext";
import { ReferralHandler } from "../components/ReferralHandler";
import {
  SustainabilityImportance,
  isSustainabilityImportantSpecific,
} from "../data/types/SustainabilityQuestions";
import { useNavigateOut } from "../hooks/useNavigateOut";
import { Plausible } from "../utils/Plausible";
import { SignupId } from "../data/signup";

const PREFIX = `/${LysaCountry.SWEDEN.toLocaleLowerCase()}`;
const CURRENCY = Currency.SEK;

export const ROUTES = {
  INTRO: `${PREFIX}/`,
  EXPERIENCE: `${PREFIX}/experience`,
  SITUATION: `${PREFIX}/situation`,
  RISK: `${PREFIX}/risk`,
  HORIZON: `${PREFIX}${HORIZON_ROUTE}`,
  SUSTAINABILITY: `${PREFIX}${SUSTAINABILITY_ROUTE}`,
  SUSTAINABILITY_PREFERENCE: `${PREFIX}${SUSTAINABILITY_PREFERENCE_ROUTE}`,
  SUSTAINABILITY_QUESTIONS: `${PREFIX}${SUSTAINABILITY_QUESTIONS_ROUTE}`,
  CONFIRM_ESG_UPDATE: `${PREFIX}${CONFIRM_ESG_UPDATE_ROUTE}`,
  ADVICE: `${PREFIX}${ADVICE_ROUTE}`,
  EDIT_ALLOCATION: `${PREFIX}${EDIT_ALLOCATION_ROUTE}`,
  RISK_WARNING: `${PREFIX}${RISK_WARNING_ROUTE}`,
  ABOUT_YOU: `${PREFIX}/about-you`,
  CRS: `${PREFIX}${CRS_ROUTE}`,
  CITIZENSHIP: `${PREFIX}${CITIZENSHIP_ROUTE}`,
  KYC: `${PREFIX}${KYC_ROUTE}`,
  PEP_IS_PEP: `${PREFIX}/is-pep`,
  PEP_STATUS: `${PREFIX}/pep-status`,
  PEP_RELATION: `${PREFIX}/pep-relation`,
  PEP_ROLE: `${PREFIX}/pep-role`,
  EMAIL: `${PREFIX}${EMAIL_ROUTE}`,
  CONFIRM: `${PREFIX}/confirm`,
  SIGN: `${PREFIX}/sign/:signupId/:orderRef?`,
  DONE: `${PREFIX}/done/:signupId`,
  EXIT: `${PREFIX}/exit`,
};

const incomeSteps = generateTranslatedArray([
  {
    step: 1000,
    stop: 110000,
  },
]);

const assetSteps = generateTranslatedArray([
  {
    step: 10000,
    stop: 1000000,
  },
  {
    step: 50000,
    stop: 6000000,
  },
  {
    step: 100000,
    stop: 10000000,
  },
]);

const situation: SituationSteps = {
  income: incomeSteps,
  expense: incomeSteps,
  debt: assetSteps,
  liquidAssets: assetSteps,
  otherAssets: assetSteps,
};

const messages = defineMessages({
  header: { id: "sweden.story.header" },
  ariaProgressLabel: { id: "sweden.story.ariaProgressLabel" },
  countryName: { id: "sweden.pep.countryName" },
});

const Router = () => {
  const localizationContext = useContext(LocalizationContext);
  const signupContext = useSignupContext();
  const pepContext = useContext(PepContext);
  const advice = useAdvice();
  const intl = useIntl();
  const history = useHistory();
  const location = useLocation();
  const loaded = useRef(false);
  const safeNavigation = useSafeNavigation();
  const navigateOut = useNavigateOut();

  const currentIndex = Object.values(ROUTES).findIndex((path) => {
    return (
      matchPath(location.pathname, {
        path,
        exact: path === ROUTES.INTRO,
      }) !== null
    );
  });

  const handleBack = () => {
    EventTracker.track({
      event: TrackerEvent.BACK_BUTTON,
    });
    history.goBack();
  };

  useEffect(() => {
    if (!loaded.current) {
      localizationContext.setState({
        currency: CURRENCY,
        language: Language.SWEDISH,
        situation: situation,
        country: LysaCountry.SWEDEN,
      });
    }
    loaded.current = true;
  }, [localizationContext]);

  const { startSign, ...bankIdRest } = useSignBankId();

  if (!loaded.current) {
    return <Spinner />;
  }

  return (
    <Story
      header={intl.formatMessage(messages.header)}
      onExit={() => {
        if (currentIndex === Object.values(ROUTES).indexOf(ROUTES.INTRO)) {
          navigateOut.exitToPublicSite();
        } else if (
          currentIndex === Object.values(ROUTES).indexOf(ROUTES.EXIT)
        ) {
          Plausible.event({
            name: Plausible.events.EXIT_CLOSE,
          });
          navigateOut.exitToPublicSite();
        } else {
          history.push(ROUTES.EXIT, { from: location.pathname });
        }
      }}
      showBack={
        (currentIndex > 0 &&
          currentIndex < Object.values(ROUTES).indexOf(ROUTES.SIGN)) ||
        currentIndex === Object.values(ROUTES).indexOf(ROUTES.EXIT)
      }
      showClose={
        currentIndex !== Object.values(ROUTES).indexOf(ROUTES.SIGN) &&
        currentIndex !== Object.values(ROUTES).indexOf(ROUTES.DONE)
      }
      onBack={handleBack}
      transitionKey={currentIndex.toString()}
      progress={(100 / Object.keys(ROUTES).length) * (currentIndex + 1)}
      ariaLabelProgress={() =>
        intl.formatMessage(messages.ariaProgressLabel, {
          current: currentIndex + 1,
          total: Object.keys(ROUTES).length,
        })
      }
    >
      <Switch {...{ order: currentIndex }} location={location}>
        <Route path={ROUTES.INTRO} exact>
          <Intro next={() => safeNavigation(ROUTES.EXPERIENCE)} />
        </Route>
        <NoDirectAccessRoute path={ROUTES.EXPERIENCE}>
          <Experience next={() => safeNavigation(ROUTES.SITUATION)} />
        </NoDirectAccessRoute>
        <NoDirectAccessRoute path={ROUTES.SITUATION}>
          <Situation next={() => safeNavigation(ROUTES.RISK)} />
        </NoDirectAccessRoute>
        <NoDirectAccessRoute path={ROUTES.RISK}>
          <SituationAnswersAccessGuard situationRoute={ROUTES.SITUATION}>
            <Risk next={() => safeNavigation(ROUTES.HORIZON)} />
          </SituationAnswersAccessGuard>
        </NoDirectAccessRoute>
        <NoDirectAccessRoute path={ROUTES.HORIZON}>
          <Horizon next={() => safeNavigation(ROUTES.SUSTAINABILITY)} />
        </NoDirectAccessRoute>
        <NoDirectAccessRoute exact path={ROUTES.SUSTAINABILITY}>
          <Sustainability
            next={() => {
              if (
                advice.input.sustainability ===
                SustainabilityImportance.IMPORTANT
              ) {
                safeNavigation(ROUTES.SUSTAINABILITY_PREFERENCE);
              } else {
                safeNavigation(ROUTES.ADVICE);
              }
            }}
          />
        </NoDirectAccessRoute>
        <NoDirectAccessRoute exact path={ROUTES.SUSTAINABILITY_PREFERENCE}>
          <SustainabilityPreference
            next={() => {
              if (isSustainabilityImportantSpecific(advice.input)) {
                safeNavigation(ROUTES.SUSTAINABILITY_QUESTIONS);
              } else {
                safeNavigation(ROUTES.ADVICE);
              }
            }}
          />
        </NoDirectAccessRoute>
        <NoDirectAccessRoute exact path={ROUTES.SUSTAINABILITY_QUESTIONS}>
          <SustainabilityImportant
            next={() => {
              const requestData = getSuitabilityAssessmentModel(
                signupContext,
                localizationContext,
                advice.input
              );

              if (!requestData) {
                throw new Error("getSuitabilityAssessmentModel - Missing data");
              }

              getSuitabilityAssessment(requestData).then(({ esgResult }) => {
                if (esgResult.esgBestMatch) {
                  safeNavigation(ROUTES.CONFIRM_ESG_UPDATE);
                } else {
                  safeNavigation(ROUTES.ADVICE);
                }
              });
            }}
          />
        </NoDirectAccessRoute>
        <NoDirectAccessRoute path={ROUTES.CONFIRM_ESG_UPDATE}>
          <ConfirmEsgUpdate next={() => safeNavigation(ROUTES.ADVICE)} />
        </NoDirectAccessRoute>
        <NoDirectAccessRoute path={ROUTES.ADVICE}>
          <Advice next={() => safeNavigation(ROUTES.ABOUT_YOU)} />
        </NoDirectAccessRoute>
        <NoDirectAccessRoute path={ROUTES.EDIT_ALLOCATION}>
          <EditAllocation
            next={() => safeNavigation(ROUTES.ABOUT_YOU)}
            nextRiskWarning={() => safeNavigation(ROUTES.RISK_WARNING)}
            allowInvestmentTypeChange={false}
          />
        </NoDirectAccessRoute>
        <NoDirectAccessRoute path={ROUTES.RISK_WARNING}>
          <RiskWarning next={() => safeNavigation(ROUTES.ABOUT_YOU)} />
        </NoDirectAccessRoute>
        <NoDirectAccessRoute path={ROUTES.ABOUT_YOU}>
          <AboutYou next={() => safeNavigation(ROUTES.CRS)} />
        </NoDirectAccessRoute>
        <NoDirectAccessRoute path={ROUTES.CRS}>
          <AboutYouAnswersAccessGuard aboutYouRoute={ROUTES.ABOUT_YOU}>
            <Crs next={() => safeNavigation(ROUTES.CITIZENSHIP)} />
          </AboutYouAnswersAccessGuard>
        </NoDirectAccessRoute>
        <NoDirectAccessRoute path={ROUTES.CITIZENSHIP}>
          <Citizenship next={() => safeNavigation(ROUTES.KYC)} />
        </NoDirectAccessRoute>
        <NoDirectAccessRoute path={ROUTES.KYC}>
          <Kyc next={() => safeNavigation(ROUTES.PEP_IS_PEP)} />
        </NoDirectAccessRoute>
        <NoDirectAccessRoute path={ROUTES.PEP_IS_PEP}>
          <IsPepPage
            next={() => {
              if (pepContext.state.type === PepType.NOT_PEP) {
                safeNavigation(ROUTES.EMAIL);
              } else if (pepContext.state.type === PepType.ME) {
                safeNavigation(ROUTES.PEP_STATUS);
              } else {
                safeNavigation(ROUTES.PEP_RELATION);
              }
            }}
          />
        </NoDirectAccessRoute>
        <NoDirectAccessRoute path={ROUTES.PEP_RELATION}>
          <RelationPage next={() => safeNavigation(ROUTES.PEP_STATUS)} />
        </NoDirectAccessRoute>
        <NoDirectAccessRoute path={ROUTES.PEP_STATUS}>
          <PepStatusPage
            countryCode={WorldCountry.SWEDEN}
            countryName={intl.formatMessage(messages.countryName)}
            next={() => safeNavigation(ROUTES.PEP_ROLE)}
            language={Language.SWEDISH}
          />
        </NoDirectAccessRoute>
        <NoDirectAccessRoute path={ROUTES.PEP_ROLE}>
          <PepRolePage
            next={() => safeNavigation(ROUTES.EMAIL)}
            language={Language.SWEDISH}
          />
        </NoDirectAccessRoute>
        <NoDirectAccessRoute path={ROUTES.EMAIL}>
          <VerifyEmail next={() => safeNavigation(ROUTES.CONFIRM)} />
        </NoDirectAccessRoute>
        <NoDirectAccessRoute path={ROUTES.CONFIRM}>
          <AboutYouAnswersAccessGuard aboutYouRoute={ROUTES.ABOUT_YOU}>
            <AdviceGuard adviceRoute={ROUTES.ADVICE}>
              <SituationAnswersAccessGuard situationRoute={ROUTES.SITUATION}>
                <Confirmation
                  next={(signupId: SignupId) => startSign(signupId)}
                />
              </SituationAnswersAccessGuard>
            </AdviceGuard>
          </AboutYouAnswersAccessGuard>
        </NoDirectAccessRoute>
        <Route path={ROUTES.SIGN}>
          <Sign startSign={startSign} {...bankIdRest} />
        </Route>
        <Route path={ROUTES.DONE}>
          <Done />
        </Route>
        <Route path={ROUTES.EXIT}>
          <Exit />
        </Route>
      </Switch>
      <ContactFooter />
    </Story>
  );
};

export const SwedenRouter: React.FC = () => {
  return (
    <SignupContextProvider defaultInvestment={10_000}>
      <ReferralHandler />
      <Router />
    </SignupContextProvider>
  );
};
