import React, { useRef, VoidFunctionComponent } from "react";
import { Button, Form, LysaFormRef } from "@lysaab/ui-2";
import { FormattedMessage } from "react-intl";
import { PAIQuestion } from "./questions/PAIQuestion";
import { SFDRQuestion } from "./questions/SFDRQuestion";
import { TaxonomyQuestion } from "./questions/TaxonomyQuestion";
import { useAdvice } from "../../state/AdviceContext";
import "./SustainabilityImportant.scss";
import { isSustainabilityImportantSpecific } from "../../data/types/SustainabilityQuestions";

export const ROUTE = "/sustainability-questions";

interface Props {
  next(): void;
}

export const SustainabilityImportant: VoidFunctionComponent<Props> = ({
  next,
}) => {
  const formRef = useRef<LysaFormRef>();
  const advice = useAdvice();

  if (!isSustainabilityImportantSpecific(advice.input)) {
    throw new Error(
      "SustainabilityImportantQuestions should only be used with SustainabilityImportance.IMPORTANT"
    );
  }

  return (
    <Form
      lysaFormRef={formRef}
      onSubmit={() => {
        if (formRef.current?.isValid) {
          next();
        }
      }}
      className="sustainability-important-questions"
    >
      <div>
        <h1 className="legend">
          <FormattedMessage id="sustainabilityImportantQuestions.header" />
        </h1>
        <SFDRQuestion
          sfdr={advice.input.sfdr}
          setSfdr={(sfdr) => advice.setInput({ sfdr: sfdr.value })}
        />
        <TaxonomyQuestion
          taxonomy={advice.input.taxonomy}
          setTaxonomy={(taxonomy) =>
            advice.setInput({ taxonomy: taxonomy.value })
          }
        />
        <PAIQuestion
          pai={advice.input.pai}
          setPai={(pai) => advice.setInput({ pai: pai.value })}
        />
        <Button
          block
          type="submit"
          data-test-id="sustainabilityImportant-next-button"
          label={
            <FormattedMessage id="sustainabilityImportantQuestions.next" />
          }
        />
      </div>
    </Form>
  );
};
