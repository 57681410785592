import React, { useContext } from "react";
import { RequiredValidator, Card, CheckboxGroup } from "@lysaab/ui-2";
import { defineMessages, useIntl } from "react-intl";
import { SwedenContext, Bank } from "../../../SwedenContext";

export const messages = defineMessages({
  bankHeader: { id: "sweden.kyc.bank.header" },
  bankRequired: { id: "sweden.kyc.bank.required" },
  [Bank.SE_SWEDBANK]: { id: "sweden.kyc.bank.alt.SV_SWEDBANK" },
  [Bank.SE_HB]: { id: "sweden.kyc.bank.alt.SV_HB" },
  [Bank.SE_NORDEA]: { id: "sweden.kyc.bank.alt.SV_NORDEA" },
  [Bank.SE_SEB]: { id: "sweden.kyc.bank.alt.SV_SEB" },
  [Bank.SE_LF]: { id: "sweden.kyc.bank.alt.SV_LF" },
  [Bank.SE_SKANDIA]: { id: "sweden.kyc.bank.alt.SV_SKANDIA" },
  [Bank.SE_DB]: { id: "sweden.kyc.bank.alt.SV_DB" },
  [Bank.SE_OTHER]: { id: "sweden.kyc.bank.alt.SV_OTHER" },
});
export const BankQuestion = () => {
  const intl = useIntl();
  const swedenContext = useContext(SwedenContext);

  return (
    <Card>
      <CheckboxGroup
        alternatives={[
          {
            text: intl.formatMessage(messages[Bank.SE_SWEDBANK]),
            value: Bank.SE_SWEDBANK,
          },
          {
            text: intl.formatMessage(messages[Bank.SE_HB]),
            value: Bank.SE_HB,
          },
          {
            text: intl.formatMessage(messages[Bank.SE_NORDEA]),
            value: Bank.SE_NORDEA,
          },
          {
            text: intl.formatMessage(messages[Bank.SE_SEB]),
            value: Bank.SE_SEB,
          },
          {
            text: intl.formatMessage(messages[Bank.SE_LF]),
            value: Bank.SE_LF,
          },
          {
            text: intl.formatMessage(messages[Bank.SE_SKANDIA]),
            value: Bank.SE_SKANDIA,
          },
          {
            text: intl.formatMessage(messages[Bank.SE_DB]),
            value: Bank.SE_DB,
          },
          {
            text: intl.formatMessage(messages[Bank.SE_OTHER]),
            value: Bank.SE_OTHER,
          },
        ]}
        header={intl.formatMessage(messages.bankHeader)}
        validators={[
          new RequiredValidator(intl.formatMessage(messages.bankRequired)),
        ]}
        onChange={(banks) => swedenContext.setState({ banks })}
        values={swedenContext.state.banks || []}
        data-test-id="kyc-bank"
      />
    </Card>
  );
};
