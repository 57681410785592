import { useIntl, defineMessages } from "react-intl";
import React, { useContext } from "react";
import { RadioGroup, Alternative, RequiredValidator } from "@lysaab/ui-2";
import { SignupContext } from "../../../state/SignupContext";
import {
  EventTracker,
  TrackerEvent,
} from "../../../utils/eventTracker/EventTracker";

const messages = defineMessages({
  someoneElsesMoneyAlternativeYes: { id: "about_you.someoneElsesMoney.yes" },
  someoneElsesMoneyAlternativeNo: { id: "about_you.someoneElsesMoney.no" },
  someoneElsesMoneyHeader: { id: "about_you.someoneElsesMoney.header" },
  someoneElsesMoneyRequired: { id: "about_you.someoneElsesMoney.required" },
});

export const SomeoneElsesMoneyQuestion = () => {
  const signupContext = useContext(SignupContext);
  const intl = useIntl();

  const someoneElsesMoneyAlternatives: Alternative<boolean>[] = [
    {
      text: intl.formatMessage(messages.someoneElsesMoneyAlternativeYes),
      value: true,
    },
    {
      text: intl.formatMessage(messages.someoneElsesMoneyAlternativeNo),
      value: false,
    },
  ];

  return (
    <RadioGroup
      header={intl.formatMessage(messages.someoneElsesMoneyHeader)}
      alternatives={someoneElsesMoneyAlternatives}
      value={signupContext.state.someoneElsesMoney}
      onChange={(value) => {
        EventTracker.track({
          event: TrackerEvent.SOMEONE_ELSES_MONEY_QUESTION,
          message: { answer: value.value },
        });
        signupContext.setState({ someoneElsesMoney: value });
      }}
      validators={[
        new RequiredValidator(
          intl.formatMessage(messages.someoneElsesMoneyRequired)
        ),
      ]}
      data-test-id="someone-elses-money"
    />
  );
};
