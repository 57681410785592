import { Card, RadioGroup, RequiredValidator } from "@lysaab/ui-2";
import { useIntl } from "react-intl";
import { useAdvice } from "../../../state/AdviceContext";
import {
  getSavingsHorizonLengthAltText,
  getSavingsHorizonLengthQuestionText,
} from "./LengthText";
import { SavingsHorizonLength } from "../../../data/types/HorizonQuestions";

export const Length = () => {
  const intl = useIntl();
  const advice = useAdvice();

  const alternatives = Object.values(SavingsHorizonLength).map((key) => ({
    text: getSavingsHorizonLengthAltText(intl, key),
    value: key,
  }));

  const value =
    typeof advice.input.savingsHorizon === "string"
      ? {
          text: getSavingsHorizonLengthAltText(
            intl,
            advice.input.savingsHorizon
          ),
          value: advice.input.savingsHorizon,
        }
      : advice.input.savingsHorizon;

  return (
    <Card>
      <RadioGroup
        alternatives={alternatives}
        header={getSavingsHorizonLengthQuestionText(intl)}
        onChange={(horizonLength) =>
          advice.setInput({ savingsHorizon: horizonLength.value })
        }
        value={value}
        validators={[
          new RequiredValidator(
            intl.formatMessage({ id: "horizon.length.required" })
          ),
        ]}
        data-test-id="horizon-length"
      />
    </Card>
  );
};
