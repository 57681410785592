import { Card, RadioGroup, RequiredValidator } from "@lysaab/ui-2";
import { useIntl, defineMessages } from "react-intl";
import { useAdvice } from "../../../state/AdviceContext";
import { EligibilityRiskAnswer } from "../../../data/types/Eligibility";

const messages = defineMessages({
  header: { id: "risk.important.header" },
  maximizeAlternative: { id: "risk.important.alt.maximize" },
  minimizeAlternative: { id: "risk.important.alt.minimize" },
  bothAlternative: { id: "risk.important.alt.both" },
  required: { id: "risk.important.required" },
});

export enum RiskImportance {
  MAXIMIZE = 10,
  MINIMIZE = 0,
  BOTH = 5,
}

export const ImportantCard = () => {
  const intl = useIntl();
  const advice = useAdvice();

  const alternatives = [
    {
      text: intl.formatMessage(messages.maximizeAlternative),
      value: RiskImportance.MAXIMIZE,
    },
    {
      text: intl.formatMessage(messages.minimizeAlternative),
      value: RiskImportance.MINIMIZE,
    },
    {
      text: intl.formatMessage(messages.bothAlternative),
      value: RiskImportance.BOTH,
    },
  ];

  const value = alternatives.find(
    (alt) => alt.value === advice.input[EligibilityRiskAnswer.IMPORTANCE]
  );

  return (
    <Card>
      <RadioGroup
        alternatives={alternatives}
        header={intl.formatMessage(messages.header)}
        onChange={(riskImportance) =>
          advice.setInput({
            [EligibilityRiskAnswer.IMPORTANCE]: riskImportance.value,
          })
        }
        value={value}
        validators={[
          new RequiredValidator(intl.formatMessage(messages.required)),
        ]}
        data-test-id="risk-importance-radiogroup"
      />
    </Card>
  );
};
