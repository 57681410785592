import {
  AllocationBarIntl,
  Card,
  Icon,
  InvestmentType,
  RiskIndicator,
} from "@lysaab/ui-2";
import { defineMessages, FormattedMessage, useIntl } from "react-intl";
import { useAdvice } from "../../state/AdviceContext";
import { AccountType } from "./ConfirmationHelpers";
import { getSavingsHorizonLengthAltText } from "../horizon/questions/LengthText";

const accountTypeMessages = defineMessages<AccountType>({
  [AccountType.VP]: {
    id: "confirmation.card.account.VP",
  },
  [AccountType.ISK_SWE]: {
    id: "confirmation.card.account.ISK_SWE",
  },
  [AccountType.VP_SWE]: {
    id: "confirmation.card.account.VP_SWE",
  },
  [AccountType.TJP_SWE]: {
    id: "confirmation.card.account.TJP_SWE",
  },
  [AccountType.DANICA_KF]: {
    id: "confirmation.card.account.DANICA_KF",
  },
});

const investmentTypeMessages = defineMessages<InvestmentType>({
  [InvestmentType.BROAD]: {
    id: "confirmation.card.investment.BROAD",
  },
  [InvestmentType.SUSTAINABLE]: {
    id: "confirmation.card.investment.SUSTAINABLE",
  },
});

interface Props {
  accountType: AccountType;
  showInvestmentType?: boolean;
}

export const ConfirmationSummarySection = ({
  accountType,
  showInvestmentType,
}: Props) => {
  const intl = useIntl();
  const {
    input: { savingsHorizon },
    result: { takenRisk, investmentType },
  } = useAdvice();

  if (
    typeof savingsHorizon === "undefined" ||
    typeof takenRisk === "undefined" ||
    typeof investmentType === "undefined"
  ) {
    throw new Error("Missing required data");
  }

  const allocationBarLabels = {
    stocks: (
      <>
        <span className="allocation-bar-value">{takenRisk} %</span>
        <span className="allocation-bar-name">
          {intl.formatMessage({ id: "confirmation.card.bar.stocks" })}
        </span>
      </>
    ),
    bonds: (
      <>
        <span className="allocation-bar-name">
          {intl.formatMessage({ id: "confirmation.card.bar.bonds" })}
        </span>
        <span className="allocation-bar-value">{100 - takenRisk} %</span>
      </>
    ),
  };

  return (
    <section>
      <Card>
        <h4>
          <Icon.Chart />
          <FormattedMessage id="confirmation.card.header" />
        </h4>

        <dl>
          <dt>
            <FormattedMessage id="confirmation.card.account.type" />
          </dt>
          <dd>{intl.formatMessage(accountTypeMessages[accountType])}</dd>
          <dt>
            <FormattedMessage id="confirmation.card.horizon" />
          </dt>
          <dd>{getSavingsHorizonLengthAltText(intl, savingsHorizon)}</dd>
          {showInvestmentType && (
            <>
              <dt>
                <FormattedMessage id="confirmation.card.investment.type" />
              </dt>
              <dd>
                {intl.formatMessage(investmentTypeMessages[investmentType])}
                <RiskIndicator
                  risk={takenRisk}
                  investmentType={investmentType}
                />
              </dd>
            </>
          )}
        </dl>

        <div>
          <FormattedMessage id="confirmation.card.bar" />
        </div>

        <AllocationBarIntl
          risk={takenRisk}
          messages={allocationBarLabels}
          data-test-id="allocation-bar"
        />
      </Card>
    </section>
  );
};
