import React, { useContext } from "react";
import { DepositInterval } from "./SharedKyc";
import { useIntl, defineMessages } from "react-intl";
import { Card, RadioGroup, RequiredValidator } from "@lysaab/ui-2";
import { SignupContext } from "../../state/SignupContext";

export const messages = defineMessages({
  header: {
    id: "kyc.depositinterval.header",
  },
  required: {
    id: "kyc.depositinterval.required",
  },
  [DepositInterval.MONTHLY]: {
    id: "kyc.depositinterval.alt.MONTHLY",
  },
  [DepositInterval.MULTIPLE_YEARLY]: {
    id: "kyc.depositinterval.alt.MULTIPLE_YEARLY",
  },
  [DepositInterval.ONCE_YEARLY]: {
    id: "kyc.depositinterval.alt.ONCE_YEARLY",
  },
  [DepositInterval.SELDOM]: {
    id: "kyc.depositinterval.alt.SELDOM",
  },
});

export const DepositIntervalQuestion = () => {
  const intl = useIntl();
  const signupContext = useContext(SignupContext);

  const alternatives = [
    {
      text: intl.formatMessage(messages[DepositInterval.MONTHLY]),
      value: DepositInterval.MONTHLY,
    },
    {
      text: intl.formatMessage(messages[DepositInterval.MULTIPLE_YEARLY]),
      value: DepositInterval.MULTIPLE_YEARLY,
    },
    {
      text: intl.formatMessage(messages[DepositInterval.ONCE_YEARLY]),
      value: DepositInterval.ONCE_YEARLY,
    },
    {
      text: intl.formatMessage(messages[DepositInterval.SELDOM]),
      value: DepositInterval.SELDOM,
    },
  ];

  const value =
    typeof signupContext.state.depositInterval === "string"
      ? alternatives.find(
          (alt) => alt.value === signupContext.state.depositInterval
        )
      : signupContext.state.depositInterval;

  return (
    <Card>
      <RadioGroup
        alternatives={alternatives}
        header={intl.formatMessage(messages.header)}
        validators={[
          new RequiredValidator(intl.formatMessage(messages.required)),
        ]}
        onChange={(depositInterval) =>
          signupContext.setState({ depositInterval: depositInterval.value })
        }
        value={value}
        data-test-id="kyc-deposits-interval"
      />
    </Card>
  );
};
